import {Component} from '@angular/core';
import {Icon} from "../../../shared/enums/icon.enum";
import {ApiUrlsProvider} from "../../../shared/utils/api-url-provider";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

  constructor(
    public apiUrlsProvider: ApiUrlsProvider
  ) {
  }

  protected readonly Icon = Icon;
}
