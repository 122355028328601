import {Component, Input} from '@angular/core';
import {MenuItem} from "../../../../../shared/interfaces/menu-item.interface";
import {Icon} from "../../../../../shared/enums/icon.enum";

@Component({
  selector: 'app-submenu-item',
  templateUrl: './submenu-item.component.html',
  styleUrls: ['./submenu-item.component.scss']
})
export class SubmenuItemComponent {

  @Input()
  submenuItem!: MenuItem;

  @Input()
  parentMenuItem!: MenuItem;

  get redirectRoute() {
    return this.parentMenuItem.route + '/' + this.submenuItem.route;
  }

  constructor() {
  }

  protected readonly Icon = Icon;
}
