export enum FieldName {
  Empty = '',
  FileName = 'fileName',
  CreatedAt = 'createdAt',
  LastOrderAt = 'lastOrderAt',
  ThumbnailUrl = 'thumbnailUrl',
  OrderItemId = 'id',
  OrderCreatedAt = 'orderCreatedAt',
  FabricCode = 'fabricCode',
  FabricName = 'fabricName',
  Width = 'width',
  Height = 'height',
  ImageSize = 'imageSize',
  RepeatType = 'repeatType',
  SizeType = 'sizeType',
  Dpi = 'dpi',
  IsFavourite = 'isFavourite',
  Code = 'code',
  TotalValue = 'totalValue',
  Currency = 'currency',
  TotalValueWithCurrency = 'totalValueWithCurrency',
  Status = 'status',
  Quantity = 'quantity',
  TotalPrice = 'totalPrice',
  ProductType = 'productType'
}
