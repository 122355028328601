export enum FilterValue {
  Empty = '',
  FileNameAsc = 'filename_asc',
  FileNameDesc = 'filename_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  Own = 'own',
  External = 'external',
  OwnAndExternal = 'own&external'
}
