export enum MenuItemId {
  Home = 'HOME',
  Designs = 'YOUR_DESIGNS',
  DesignsOrdered = 'ORDERED_DESIGNS',
  DesignsFavourite = 'FAVOURITE_DESIGNS',
  Orders = 'ORDERS_HISTORY',
  Account = 'ACCOUNT',

  Cart = 'CART',

  More = 'MORE',
  MoreOrderHistory = 'MORE_ORDER_HISTORY',
  MoreYourAccount = 'MORE_YOUR_ACCOUNT',
  MoreLogout = 'MORE_LOGOUT',
}
