import {Component, ElementRef, Optional, Renderer2, Self, ViewChild, ViewEncapsulation} from '@angular/core';
import {BaseControlComponent} from "../base-control/base-control.component";
import {ControlValueAccessor, NgControl} from "@angular/forms";
import {DateTimeFormat} from "../../enums/date-time-format.enum";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-extended-datepicker',
  templateUrl: './extended-datepicker.component.html',
  styleUrls: ['./extended-datepicker.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class ExtendedDatepickerComponent extends BaseControlComponent implements ControlValueAccessor{

  constructor(
    @Self()
    @Optional()
    override ngControl: NgControl,
    private _renderer: Renderer2,
    private datePipe: DatePipe
  ) {
    super(ngControl);
    this.ngControl.valueAccessor = this;
  }

  @ViewChild('datepickerElement') private _inputElement!: ElementRef;

  writeValue(obj: any): void {
    const formattedValue = this.datePipe.transform(obj, DateTimeFormat.Datepicker);

    if (this._inputElement)
      this._renderer.setProperty(this._inputElement.nativeElement, 'value', formattedValue);
    this.value = formattedValue;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
