import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Icon} from "../../enums/icon.enum";
import {LoadingIndicatorType} from "../../enums/loading-indicator.enum";

@Component({
  selector: 'app-extended-button',
  templateUrl: './extended-button.component.html',
  styleUrls: ['./extended-button.component.scss']
})
export class ExtendedButtonComponent {
  @Input()
  loading = false;

  @Input()
  icon!: Icon;

  @Input()
  text!: string;

  @Input()
  disabled = false;

  @Output()
  clicked: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  protected readonly LoadingIndicatorType = LoadingIndicatorType;
}
