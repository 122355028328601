import {Component, OnInit} from '@angular/core';
import {DialogRef} from "@ngneat/dialog";

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html'
})
export class ImageComponent implements OnInit{

  imageUrl!: string;

  constructor(
    public dialogRef: DialogRef,
  ) {
  }

  ngOnInit(): void {
    this.imageUrl = this.dialogRef.data.imageUrl;
  }
}
