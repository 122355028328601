import {NgModule} from '@angular/core';
import {LayoutComponent} from './layout.component';
import {RouterModule} from '@angular/router';
import {LeftContainerComponent} from './menu/containers/left/left-container.component';
import {MenuItemComponent} from './menu/items/menu-item/menu-item.component';
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {SharedModule} from "../../shared/shared.module";
import {ContentComponent} from './content/content.component';
import {CartLinkComponent} from './content/cart-link/cart-link.component';
import {HeaderComponent} from './header/header.component';
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";
import {AngularSvgIconModule} from "angular-svg-icon";
import {BottomContainerComponent} from './menu/containers/bottom/bottom-container.component';
import {TopContainerComponent} from './menu/containers/top/top-container.component';
import {MenuComponent} from './menu/menu.component';
import {SubmenuItemComponent} from './menu/items/submenu-item/submenu-item.component';
import {LanguageSwitchComponent} from './language-switch/language-switch.component';

@NgModule({
  imports: [
    RouterModule,
    NgForOf,
    NgIf,
    NgClass,
    SharedModule,
    NgSelectModule,
    FormsModule,
    AngularSvgIconModule
  ],
  providers: [],
  declarations: [
    LayoutComponent,
    LeftContainerComponent,
    MenuItemComponent,
    ContentComponent,
    CartLinkComponent,
    HeaderComponent,
    BottomContainerComponent,
    TopContainerComponent,
    MenuComponent,
    SubmenuItemComponent,
    LanguageSwitchComponent],
    exports: [LayoutComponent, HeaderComponent]
})
export class LayoutModule {
}
