import {Injectable} from '@angular/core';
import {MenuItemType} from "../../../../shared/enums/menu-item-type.enum";
import {marker as _} from "@biesbjerg/ngx-translate-extract-marker";
import {Icon} from "../../../../shared/enums/icon.enum";
import {RoutePath} from "../../../../shared/enums/route-path.enum";
import {Subject} from "rxjs";
import {Router} from "@angular/router";
import {AuthService} from "../../../../shared/services/auth.service";
import {ApiUrlsProvider} from "../../../../shared/utils/api-url-provider";
import {MenuItemId} from "../../../../shared/enums/menu-item-id.enum";
import {MenuItem} from "../../../../shared/interfaces/menu-item.interface";
import {TopMenuService} from "./top-menu.service";

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  public redirectTo$: Subject<MenuItem> = new Subject<MenuItem>();
  public redirectToExternal$: Subject<MenuItem> = new Subject<MenuItem>();
  public logout$: Subject<any> = new Subject<any>();

  constructor(
    private authService: AuthService,
    private router: Router,
    private apiUrlsProvider: ApiUrlsProvider,
    private topMenuService: TopMenuService
  ) {
    this.redirectTo$
      .pipe()
      .subscribe((menuItem) => {
        if (menuItem.routeParent != null){
          this.router.navigate([menuItem.routeParent, menuItem.route]);
        } else {
          this.router.navigate([menuItem.route]);
        }

        this.topMenuService.close();
      });

    this.redirectToExternal$
      .pipe()
      .subscribe((menuItem) => {
        window.open(menuItem.route, "_blank");
      });

    this.logout$.pipe()
      .subscribe(() => {
        this.authService.logout();
      });
  }

  public menuItems = [
    {
      id: MenuItemId.Home,
      type: MenuItemType.All,
      label: _('GENERAL.MENU_ITEMS.HOME'),
      icon: Icon.House,
      route: RoutePath.Home,
      isVisible: true,
      func: this.redirectTo$
    },
    {
      id: MenuItemId.Designs,
      type: MenuItemType.All,
      label: _('GENERAL.MENU_ITEMS.YOUR_DESIGNS'),
      icon: Icon.Collection,
      route: RoutePath.Designs,
      func: this.redirectTo$,
      isVisible: true,
      submenus: [
        {
          id: MenuItemId.DesignsOrdered,
          type: MenuItemType.All,
          label: _('GENERAL.MENU_ITEMS.ORDERED_DESIGNS'),
          route: RoutePath.DesignsOrdered,
          routeParent: RoutePath.Designs,
          isVisible: false,
          func: this.redirectTo$
        },
        {
          id: MenuItemId.DesignsFavourite,
          type: MenuItemType.All,
          label: _('GENERAL.MENU_ITEMS.FAVOURITE_DESIGNS'),
          route: RoutePath.DesignsFavourite,
          routeParent: RoutePath.Designs,
          isVisible: false,
          func: this.redirectTo$
        }
      ]
    },
    {
      id: MenuItemId.Orders,
      type: MenuItemType.LeftAndTopMenu,
      label: _('GENERAL.MENU_ITEMS.ORDERS_HISTORY'),
      icon: Icon.History,
      route: RoutePath.Orders,
      isVisible: true,
      func: this.redirectTo$
    },
    {
      id: MenuItemId.Account,
      type: MenuItemType.LeftAndTopMenu,
      label: _('GENERAL.MENU_ITEMS.ACCOUNT'),
      icon: Icon.UserCircle,
      route: RoutePath.Account,
      isVisible: true,
      func: this.redirectTo$
    },
    {
      id: MenuItemId.Cart,
      type: MenuItemType.BottomMenu,
      label: _('GENERAL.MENU_ITEMS.CART'),
      icon: Icon.Cart,
      route: this.apiUrlsProvider.cartUrl(),
      isVisible: true,
      func: this.redirectToExternal$
    },
    {
      id: MenuItemId.More,
      type: MenuItemType.BottomMenu,
      label: _('GENERAL.MENU_ITEMS.MORE'),
      icon: Icon.DotsHorizontal,
      route: RoutePath.Orders,
      isVisible: true,
      submenus: [
        {
          id: MenuItemId.MoreOrderHistory,
          type: MenuItemType.BottomMenu,
          label: _('GENERAL.MENU_ITEMS.ORDERS_HISTORY'),
          route: RoutePath.Orders,
          isVisible: true,
          func: this.redirectTo$
        },
        {
          id: MenuItemId.MoreYourAccount,
          type: MenuItemType.BottomMenu,
          label: _('GENERAL.MENU_ITEMS.ACCOUNT'),
          route: RoutePath.Account,
          isVisible: true,
          func: this.redirectTo$
        },
        {
          id: MenuItemId.MoreLogout,
          type: MenuItemType.BottomMenu,
          label: _('GENERAL.MENU_ITEMS.LOGOUT'),
          route: RoutePath.Empty,
          isVisible: true,
          func: this.logout$
        }
      ]
    },
  ];
}
