import { Component } from '@angular/core';

@Component({
  selector: 'app-reset-container',
  templateUrl: './reset-container.component.html',
  styleUrls: ['./reset-container.component.scss']
})
export class ResetContainerComponent {

}
