<div>
  <ng-container *ngIf="message">
    <p class="s">
      {{ message }}
    </p>
  </ng-container>
  <ng-container *ngIf="!message">
    <p class="s">
      {{ 'GENERAL.TABLE.NO_ITEMS' | translate }}
    </p>
  </ng-container>
</div>
