import { Component } from '@angular/core';
import {Icon} from "../../../shared/enums/icon.enum";

@Component({
  selector: 'app-delivery-options',
  templateUrl: './delivery-options.component.html',
  styleUrls: ['./delivery-options.component.scss']
})
export class DeliveryOptionsComponent {

    protected readonly Icon = Icon;
}
