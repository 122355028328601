export enum FilterKey {
  Page = 'page',
  PerPage = 'per_page',
  SortedBy = 'sorted_by',
  FilteredBySource = 'filtered_by_source',
  FilteredByFilename = 'filtered_by_filename',
  FilteredByCode = 'filtered_by_code',
  FilteredByIsFavourite = 'filtered_by_is_favourite',
  FilteredByCreatedAtFrom = 'with_created_at_gte',
  FilteredByCreatedAtTo = 'with_created_at_lte',
}
