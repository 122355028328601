export interface InvoiceAddress {
  first_name: string,
  last_name: string,
  street: string,
  house_no: string,
  postcode: string,
  city: string,
  country: string,
  vat_id: string
}

export class InvoiceAddressEntity {
  constructor(
    public firstName: string,
    public lastName: string,
    public fullName: string,
    public street: string,
    public houseNo: string,
    public postCode: string,
    public city: string,
    public country: string,
    public vatId: string,
  ) {
  }

  public static fromData(data: InvoiceAddress): InvoiceAddressEntity {
    return new InvoiceAddressEntity(
      data.first_name,
      data.last_name,
      data.first_name.concat(' ', data.last_name),
      data.street,
      data.house_no,
      data.postcode,
      data.city,
      data.country,
      data.vat_id,
    );
  }
}
