export interface AccountManager {
  first_name: string;
  last_name: string;
  email: string;
}

export class AccountManagerEntity {
  constructor(public firstName: string, public lastName: string, public fullName: string, public email: string) {}

  public static fromData(data: AccountManager): AccountManagerEntity {
    return new AccountManagerEntity(data?.first_name, data?.last_name, data?.first_name.concat(' ', data?.last_name), data?.email);
  }

  public toData(): AccountManager {
    return {
      first_name: this.firstName,
      last_name: this.lastName,
      email: this.email
    };
  }
}
