import {Injectable} from '@angular/core';
import {ApiUrlsProvider} from "../../utils/api-url-provider";
import {HttpClient, HttpParams, HttpResponse} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {ApiPath} from "../../enums/api-path.enum";
import {Page} from "../../entities/page.entity";
import {OrdersFilterEntity} from "../../entities/orders-filter.entity";
import {OrdersPageEntity} from "../../entities/orders-page.entity";
import {Order} from "../../entities/order.entity";
import {OrderDetails, OrderDetailsEntity} from "../../entities/order-details.entity";
import {FilterKey} from "../../enums/filter-key.enum";
import {RequestOptions} from "./request-options";

@Injectable({
  providedIn: 'root'
})
export class OrdersHttpService {

  constructor(
    private apiUrlProvider: ApiUrlsProvider,
    private http: HttpClient
  ) {
  }

  public loadOrders(filters: OrdersFilterEntity): Observable<OrdersPageEntity> {
    const url = this.apiUrlProvider.apiLanguageUrl(ApiPath.GetOrders);
    let params = new HttpParams();

    params = params.set(FilterKey.Page, filters.page);
    params = params.set(FilterKey.PerPage, filters.per_page);
    params = params.set(FilterKey.SortedBy, filters.sorted_by);

    if (filters.filtered_by_code)
      params = params.set(FilterKey.FilteredByCode, filters.filtered_by_code);

    if (filters.filtered_by_is_favourite)
      params = params.set(FilterKey.FilteredByIsFavourite, filters.filtered_by_is_favourite);

    if (filters.filtered_by_created_at_from)
      params = params.set(FilterKey.FilteredByCreatedAtFrom, filters.filtered_by_created_at_from);

    if (filters.filtered_by_created_at_to)
      params = params.set(FilterKey.FilteredByCreatedAtTo, filters.filtered_by_created_at_to);

    return this.http.get<Page<Order>>(url, {params}).pipe(map((response) => OrdersPageEntity.fromData(response)));
  }

  public loadOrderDetails(orderId: number): Observable<OrderDetailsEntity> {
    const url = this.apiUrlProvider.apiLanguageUrl(ApiPath.GetOrderDetails.replace(':orderId', orderId.toString()));

    return this.http.get<OrderDetails>(url).pipe(map((response) => OrderDetailsEntity.fromData(response)));
  }

  public addOrderToFavourites(orderId: number): Observable<OrderDetailsEntity> {
    const url = this.apiUrlProvider.apiLanguageUrl(ApiPath.AddOrderToFavourites.replace(':orderId', orderId.toString()));

    return this.http.post<OrderDetails>(url, {}).pipe(map((response) => OrderDetailsEntity.fromData(response)));
  }

  public removeOrderFromFavourites(orderId: number): Observable<OrderDetailsEntity> {
    const url = this.apiUrlProvider.apiLanguageUrl(ApiPath.RemoveOrderFromFavourites.replace(':orderId', orderId.toString()));

    return this.http.delete<OrderDetails>(url).pipe(map((response) => OrderDetailsEntity.fromData(response)));
  }

  public getOrderInvoice(orderId: number): Observable<HttpResponse<Blob>> {
    const url = this.apiUrlProvider.apiLanguageUrl(ApiPath.GetOrderInvoice.replace(':orderId', orderId.toString()));

    const requestOptions = new RequestOptions();
    requestOptions.responseType = 'blob';

    return this.http.request('GET', url, requestOptions);
  }

  public reorderWholeOrder(orderId: number): Observable<void> {
    const url = this.apiUrlProvider.apiLanguageUrl(ApiPath.ReorderWholeOrder.replace(':orderId', orderId.toString()));

    return this.http.post<void>(url, {}).pipe();
  }

  public reorderSingleItem(orderId: number, orderItemId: number): Observable<void> {
    const url = this.apiUrlProvider.apiLanguageUrl(ApiPath.ReorderSingleItem
      .replace(':orderId', orderId.toString())
      .replace(':orderItemId', orderItemId.toString()));

    return this.http.post<void>(url, {}).pipe();
  }
}
