export interface User {
  id: number;
  code: string;
  first_name: string;
  last_name: string;
  email: string;
  created_at: string;
  updated_at: string;
  is_active: boolean;
  currency_id: number;
  country_location: string;
  same_billing_as_shipping: boolean;
  wants_newsletter: boolean;
  is_business: boolean;
  account_manager_id: number;
  send_payment_reminder: true;
  ga4_user_id: string
}

export class UserEntity {
  constructor(
    public id: number,
    public code: string,
    public firstName: string,
    public lastName: string,
    public email: string,
    public createdAt: string,
    public updatedAt: string,
    public is_active: boolean,
    public currencyIdd: number,
    public countryLocation: string,
    public sameBillingAsShipping: boolean,
    public wantsNewsletter: boolean,
    public isBusiness: boolean,
    public accountManagerId: number,
    public sendPaymentReminder: boolean,
    public ga4UserId: string
  ) {}

  public static fromData(data: User): UserEntity {
    return new UserEntity(
      data.id,
      data.code,
      data.first_name,
      data.last_name,
      data.email,
      data.created_at,
      data.updated_at,
      data.is_active,
      data.currency_id,
      data.country_location,
      data.same_billing_as_shipping,
      data.wants_newsletter,
      data.is_business,
      data.account_manager_id,
      data.send_payment_reminder,
      data.ga4_user_id,
    );
  }
}
