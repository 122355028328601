export enum ApiPath {
  Login = 'clients/sign_in',
  Logout = 'clients/sign_out',
  Register = 'clients',
  SendResetPasswordLink = 'clients/password',
  ResetPassword = 'clients/password',

  Account = 'client/account/me',
  AccountUpdate = 'client/account/me',
  ChangePassword = 'client/account/password',
  GetInvoiceAddress = 'client/orders/last_invoice_address',
  GetShippingAddress = 'client/orders/last_shipping_address',

  GetDesigns = 'client/designs',
  GetDesignDownloadUrl = 'client/designs/:uploaded_photo_id/download_url',
  GetDesignHistory = 'client/designs/with/:designId/:source',
  GetFavourites = 'client/favourites',
  DeleteFavourite = 'client/favourites/:favouriteId',

  GetOrders = 'client/orders/history',
  GetOrderDetails = 'client/orders/:orderId',
  AddOrderToFavourites = 'client/orders/favourite/:orderId',
  RemoveOrderFromFavourites = 'client/orders/favourite/:orderId',
  GetOrderInvoice = 'client/orders/:orderId/invoice',
  ReorderWholeOrder = 'client/orders/:orderId/reorder',
  ReorderSingleItem = 'client/orders/:orderId/reorder_item/:orderItemId',

  GetCartSummary = 'client/cart/summary'
}
