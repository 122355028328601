<div class="container">
  <h2 class="mb-big">{{ 'FTL.FORMS.RESET_PASSWORD.HEADER' | translate }}</h2>

  <ng-container *ngIf="(sendPasswordResetLinkSuccess$ | async) !== true">
    <p class="s mb-big">
      {{ 'FTL.FORMS.RESET_PASSWORD.DESCRIPTION' | translate }}
    </p>

    <form
      [formGroup]="form"
      (ngSubmit)="submit$.next([$event, form])"
    >
      <app-extended-input
        formControlName="emailControl"
        [icon]="Icon.Envelop"
        [label]="'FTL.FORMS.RESET_PASSWORD.LABEL_EMAIL' | translate"
        [placeholder]="'FTL.FORMS.RESET_PASSWORD.PLACEHOLDER_EMAIL' | translate"
        [validationErrors]="[
          {
            key: 'required',
            value: 'FTL.FORMS.VALIDATION.EMAIL.REQUIRED' | translate
          },
          { key: 'email', value: 'FTL.FORMS.VALIDATION.EMAIL.EMAIL' | translate },
          {
            key: 'minlength',
            value: 'FTL.FORMS.VALIDATION.EMAIL.MINLENGTH' | translate
          },
          {
            key: 'maxlength',
            value: 'FTL.FORMS.VALIDATION.EMAIL.MAXLENGTH' | translate
          }
        ]"
        type="email"
      ></app-extended-input>

      <div class="mt-big mb-medium">
        <app-extended-button
          class="filled wide"
          [text]="'FTL.FORMS.RESET_PASSWORD.BUTTON_SEND_LINK' | translate"
          [disabled]="form.invalid || (authServiceLoading$ | async)!"
        ></app-extended-button>
      </div>
    </form>

    <p
      class="text-error"
      *ngIf="errorMessage"
    >
      {{ errorMessage }}
    </p>
  </ng-container>

  <ng-container *ngIf="(sendPasswordResetLinkSuccess$ | async) === true">
    <div class="email-container">
      <div class="icon">
        <app-icon
          [icon]="Icon.CheckCircle"
          [clickable]="false"
        ></app-icon>
      </div>
      <p class="m bold">{{ 'FTL.FORMS.RESET_PASSWORD.EMAIL_SENT' | translate }}</p>
    </div>

    <p class="s mt-medium">{{ 'FTL.FORMS.RESET_PASSWORD.CHECK_MAILBOX' | translate }}</p>
  </ng-container>
</div>
