import {Pipe, PipeTransform} from '@angular/core';
import {OrderStatus} from "../enums/order-status.enum";
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';
import {FieldName} from "../enums/field-name.enum";
import {RepeatType} from "../enums/repeat-type.enum";
import {ProductType} from "../enums/product-type.enum";

@Pipe({
  name: 'enumTranslate'
})
export class EnumTranslatePipe implements PipeTransform {

  transform(value: any, fieldName: FieldName | string): string {
    switch (fieldName) {
      case FieldName.Status:
        return this.interpretOrderStatus(value);
      case FieldName.RepeatType:
        return this.interpretRepeatType(value);
      case FieldName.ProductType:
        return this.interpretProductType(value);
      case FieldName.SizeType:
        return this.interpretProductType(value);
    }

    return '';
  }

  interpretOrderStatus(orderStatus: OrderStatus): string {
    switch (orderStatus) {
      case OrderStatus.Placed:
        return _('PANEL.ORDERS.ORDER_STATUS.PLACED');
      case OrderStatus.Paid:
        return _('PANEL.ORDERS.ORDER_STATUS.PAID');
      case OrderStatus.InProgress:
        return _('PANEL.ORDERS.ORDER_STATUS.IN_PROGRESS');
      case OrderStatus.Sent:
        return _('PANEL.ORDERS.ORDER_STATUS.SENT');
      case OrderStatus.Cancelled:
        return _('PANEL.ORDERS.ORDER_STATUS.CANCELLED');
      default:
        return '-';
    }
  }

  interpretRepeatType(repeatType: RepeatType): string {
    switch (repeatType) {
      case RepeatType.NoRepeat:
        return _('PANEL.ORDERS.REPEAT_TYPE.NO_REPEAT');
      case RepeatType.Standard:
        return _('PANEL.ORDERS.REPEAT_TYPE.STANDARD');
      case RepeatType.MovedRow:
        return _('PANEL.ORDERS.REPEAT_TYPE.MOVED_ROW');
      case RepeatType.MovedColumn:
        return _('PANEL.ORDERS.REPEAT_TYPE.MOVED_COLUMN');
      case RepeatType.MirrorBasic:
        return _('PANEL.ORDERS.REPEAT_TYPE.MIRROR_BASIC');
      case RepeatType.MirrorFull:
        return _('PANEL.ORDERS.REPEAT_TYPE.MIRROR_FULL');
      default:
        return '-';
    }
  }

  interpretProductType(productType: ProductType): string {
    switch (productType) {
      case ProductType.Sampler:
        return _('PANEL.ORDERS.PRODUCT_TYPE.SAMPLER');
      case ProductType.Coupon:
        return _('PANEL.ORDERS.PRODUCT_TYPE.COUPON');
      case ProductType.Meter:
        return _('PANEL.ORDERS.PRODUCT_TYPE.METER');
      default:
        return '-';
    }
  }
}
