import {Component} from '@angular/core';
import {MenuItemType} from "../../../../../shared/enums/menu-item-type.enum";

@Component({
  selector: 'app-top-container',
  templateUrl: './top-container.component.html',
  styleUrls: ['./top-container.component.scss']
})
export class TopContainerComponent {

  protected readonly MenuItemType = MenuItemType;
}
