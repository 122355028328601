import {RepeatType} from "../enums/repeat-type.enum";
import {ProductType} from "../enums/product-type.enum";

export interface OrderItem {
  id: number,
  name: string,
  repeat_type: RepeatType,
  dpi: number,
  quantity: string,
  product_type: ProductType,
  type: string,
  unit_price: string,
  total_price: string,
  width_in_px: number,
  height_in_px: number,
  thumbnail_url: string,
  fabric_name: string,
  width_in_cm: number,
  height_in_cm: number,
  sku_path: string,
  ordinary_sampler_sections?: string[]
}

export class OrderItemEntity {
  constructor(
    public id: number,
    public name: string,
    public repeatType: RepeatType,
    public dpi: number,
    public quantity: string,
    public productType: ProductType,
    public type: string,
    public unitPrice: string,
    public totalPrice: string,
    public widthInPx: number,
    public heightInPx: number,
    public thumbnailUrl: string,
    public fabricName: string,
    public widthInCm: number,
    public heightInCm: number,
    public designUrl: string,
    public ordinarySamplerSections: string[]
  ) {
  }

  public static fromData(data: OrderItem): OrderItemEntity {
    return new OrderItemEntity(
      data.id,
      data.name,
      data.repeat_type,
      data.dpi,
      data.quantity,
      data.product_type,
      data.type,
      data.unit_price,
      data.total_price,
      data.width_in_px,
      data.height_in_px,
      data.thumbnail_url,
      data.fabric_name,
      data.width_in_cm,
      data.height_in_cm,
      data.sku_path,
      data.ordinary_sampler_sections ??= []
    );
  }
}
