import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LayoutComponent} from './layout/layout.component';
import {OnboardingModule} from '../onboarding/onboarding.module';
import {RoutePath} from "../shared/enums/route-path.enum";
import {Error404Component} from "../shared/components/error404/error404.component";
import {AuthGuard} from "../shared/guards/auth.guard";

const routes: Routes = [
  {
    path: RoutePath.Empty,
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: RoutePath.Empty,
        redirectTo: RoutePath.Home,
      },
      {
        path: RoutePath.Home,
        loadChildren: () => import('../home/home.module').then((m) => m.HomeModule),
      },
      {
        path: RoutePath.Designs,
        loadChildren: () => import('../designs/designs.module').then((m) => m.DesignsModule),
      },
      {
        path: RoutePath.Orders,
        loadChildren: () => import('../orders/orders.module').then((m) => m.OrdersModule),
      },
      {
        path: RoutePath.Account,
        loadChildren: () => import('../account/account.module').then((m) => m.AccountModule),
      },
    ]
  },
  {
    path: '**',
    component: Error404Component
  }
];

@NgModule({
  imports: [
    OnboardingModule,
    RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
