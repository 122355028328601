<ng-container *ngIf="!isLoading">
  <svg-icon
    *ngIf="icon"
    [svgStyle]="{ fill: fillColor }"
    [src]="apiUrlsProvider.clientAppBaseUrl() + '/assets/images/icons/' + icon + '.svg'"
    (click)="clicked$.next()"
  ></svg-icon>
</ng-container>

<app-loading-indicator
  *ngIf="isLoading"
  [loadingIndicatorType]="LoadingIndicatorType.IconButton">
</app-loading-indicator>
