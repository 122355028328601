<form [formGroup]="form"
      class="form-item">
  <app-search-input
    class="input-item glass-primary"
    formControlName="searchControl"
    [placeholder]="'PANEL.ORDERS.PLACEHOLDER_SEARCH_ORDER' | translate"
    [delay]="0"
  ></app-search-input>

  <app-extended-button
    class="button-item filled"
    (clicked)="search$.next()"
    [text]="'PANEL.ORDERS.BUTTON_SEARCH' | translate"
  ></app-extended-button>
</form>
