<div>
  <ng-container *ngFor="let item of items!">
    <p
      class="xs semi"
      (click)="executeItemAction$.next(item)"
    >
      {{ item.label | translate | uppercase }}
    </p>
  </ng-container>

  <div class="mt-medium">
    <app-extended-button
      class="filled semi wide"
      [text]="'GENERAL.MENU.DIALOG.CLOSE' | translate"
      (clicked)="closeDialog$.next()"
    ></app-extended-button>
  </div>
</div>
