import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Icon} from "../../enums/icon.enum";
import {ApiUrlsProvider} from "../../utils/api-url-provider";
import {Subject, takeUntil} from "rxjs";
import {BaseComponent} from "../base/base.component";
import {LoadingIndicatorType} from "../../enums/loading-indicator.enum";

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent extends BaseComponent implements OnInit {

  @Input()
  icon!: Icon;

  @Input()
  isLoading = false;

  @Input()
  stateColor = false;

  @Output()
  clicked: EventEmitter<void> = new EventEmitter<void>();

  public clicked$: Subject<void> = new Subject<void>();

  public fillColor = '#353535';

  constructor(
    public apiUrlsProvider: ApiUrlsProvider
  ) {
    super();
  }

  ngOnInit(): void {
    this.clicked$.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (this.stateColor) {
          this.fillColor = '#DA557A';
        }

        this.clicked.next();
      });
  }

  protected readonly LoadingIndicatorType = LoadingIndicatorType;
}
