import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  Optional,
  Renderer2,
  Self,
  ViewChild
} from '@angular/core';
import {BaseControlComponent} from "../base-control/base-control.component";
import {ControlValueAccessor, NgControl} from "@angular/forms";

@Component({
  selector: 'app-extended-checkbox',
  templateUrl: './extended-checkbox.component.html',
  styleUrls: ['./extended-checkbox.component.scss']
})
export class ExtendedCheckboxComponent extends BaseControlComponent implements AfterViewInit, ControlValueAccessor{

  @Input()
  right = false;

  constructor(
    @Self()
    @Optional()
    override ngControl: NgControl,
    private _renderer: Renderer2
  ) {
    super(ngControl);
    this.ngControl.valueAccessor = this;
  }

  @ViewChild('checkboxElement') private _inputElement!: ElementRef;

  writeValue(obj: boolean): void {
    if (this._inputElement) {
      if(obj)
        this._renderer.setProperty(this._inputElement.nativeElement, 'checked', true);
      else
        this._renderer.setProperty(this._inputElement.nativeElement, 'checked', false);
    }

    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  override onChange(event: any) {
    this.formControl.control?.markAsTouched();
    this.formControl.control?.setValue(event.target.checked);
    this._onChange(event.target.checked);
  }

  ngAfterViewInit(): void {
    if(this.initialValue){
      this._renderer.setProperty(this._inputElement.nativeElement, 'checked', this.initialValue);
    }
  }
}
