import {Component, Input, OnInit} from '@angular/core';
import {MenuItem} from "../../../shared/interfaces/menu-item.interface";
import {Subject, takeUntil} from "rxjs";
import {MenuItemType} from "../../../shared/enums/menu-item-type.enum";
import {MenuService} from "./services/menu.service";
import {BaseComponent} from "../../../shared/components/base/base.component";
import {SubmenuType} from "../../../shared/enums/submenu-type.enum";
import {DialogService} from "@ngneat/dialog";
import {ButtonDialogComponent} from "../../../shared/components/button-dialog/button-dialog.component";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent extends BaseComponent implements OnInit {

  @Input()
  menuItemType!: MenuItemType;

  @Input()
  submenuType: SubmenuType = SubmenuType.Dropdown;

  @Input()
  vertical = true;

  public menuItems!: MenuItem[];

  public showSubmenusDialog$: Subject<MenuItem> = new Subject<MenuItem>();
  public toggleSubmenusDropdown$: Subject<MenuItem> = new Subject<MenuItem>();

  constructor(
    private menuService: MenuService,
    private dialogService: DialogService
  ) {
    super();
  }

  ngOnInit(): void {
    this.menuItems = this.menuService.menuItems.filter(mi => mi.type & this.menuItemType);

    this.toggleSubmenusDropdown$.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe((menuItem) => {
        this.menuItems.find(mi => mi.id == menuItem.id)?.submenus?.forEach(mi => mi.isVisible = !mi.isVisible);
      });

    this.showSubmenusDialog$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((menuItem) => {
        this.dialogService.open(ButtonDialogComponent, {
          id: 'submenus-dialog',
          closeButton: false,
          width: 'auto',
          data: {
            items: menuItem.submenus
          }
        });
      });
  }

  protected readonly SubmenuType = SubmenuType;
  protected readonly MenuItemType = MenuItemType;
}
