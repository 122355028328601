import {Injectable} from '@angular/core';
import * as FileSaver from 'file-saver';
import {HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DownloadFileService {
  constructor() {}

  public downloadFileByResponseData(responseData: any, initialFileName?: string, filenameNoExt?: string): Observable<void> {
    let fileName = this.getFileNameFromResponseContentDisposition(responseData);
    if (fileName == null) {
      fileName = initialFileName!;
    } else if (filenameNoExt != null) {
      const ext = fileName.substr(fileName.lastIndexOf('.') + 1);
      fileName = `${filenameNoExt}.${ext}`;
    }
    if (typeof responseData.body === 'object') {
      FileSaver.saveAs(responseData.body, decodeURIComponent(fileName));
      return new Observable((subscriber) => {
        subscriber.complete();
      });
    } else {
      return new Observable((subscriber) => {
        const error = new Error(responseData.status + ' ' + responseData.body);
        subscriber.error(error);
      });
    }
  }

  public downloadByUrl(path: string, name: string): void {
    FileSaver.saveAs(path, name);
  }

  private getFileNameFromResponseContentDisposition(res: HttpResponse<any>) {
    const contentDisposition = res.headers.get('content-disposition') || '';
    const matches = /filename=([^;]+)/gi.exec(contentDisposition);
    if (matches == null || matches.length < 2) {
      return null;
    }
    const fileName = (matches[1] || 'untitled').replace(new RegExp('"', 'g'), '').trim();
    return fileName;
  }
}
