<div
  tabindex="1"
  class="flex-row center"
  (click)="sortingOptionChanged$.next()"
>
  <app-icon [icon]="Icon.Check"></app-icon>
  <p class="xs semi">
    {{ sortDescription | translate }}
  </p>
</div>
