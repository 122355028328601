<app-icon
  [icon]="Icon.Menu"
  (click)="toggleTopMenu$.next()"
></app-icon>

<div class="flex-column">
  <app-header></app-header>
  <div class="flex-row wrapper">
    <app-left-container *ngIf="leftMenuVisibility$ | async"></app-left-container>
    <app-content></app-content>
  </div>
  <app-bottom-container *ngIf="bottomMenuVisibility$ | async"></app-bottom-container>
</div>

<app-top-container *ngIf="topMenuVisibility$ | async"></app-top-container>
