import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";
import {RoutePath} from "../enums/route-path.enum";
import {Observable, of} from "rxjs";

@Injectable()
export class NoAuthGuard implements CanActivate {

  constructor(
    private router: Router
  ) {
  }

  canActivate(): Observable<boolean> {
    const isLogged = localStorage.getItem('isLogged') !== 'true';

    if(!isLogged){
      this.router.navigate([RoutePath.Home]);
    }

    return of(isLogged);
  }
}
