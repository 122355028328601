import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginContainerComponent} from "./login-container/login-container.component";
import {OnboardingComponent} from "./onboarding.component";
import {RoutePath} from "../shared/enums/route-path.enum";
import {NoAuthGuard} from "../shared/guards/no-auth.guard";

const routes: Routes = [
  {
    path: RoutePath.Empty,
    component: OnboardingComponent,
    canActivate: [NoAuthGuard],
    children: [
      {
        path: RoutePath.Empty,
        redirectTo: RoutePath.Login
      },
      {
        path: RoutePath.Login,
        component: LoginContainerComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OnboardingRoutingModule {
}
