import {Injectable} from '@angular/core';
import {OrdersHttpService} from "../../shared/services/http/orders-http.service";
import {
  catchError,
  concatMap,
  debounceTime,
  EMPTY,
  map,
  Observable,
  Subject,
  take,
} from "rxjs";
import {OrdersFilterEntity} from "../../shared/entities/orders-filter.entity";
import {ApiErrorEntity} from "../../shared/entities/api-error.entity";
import {OrdersPageEntity} from "../../shared/entities/orders-page.entity";
import {indicate} from "../../../../operators";
import {OrderDetailsEntity} from "../../shared/entities/order-details.entity";
import {DownloadFileService} from "../../shared/services/download-file.service";
import {CartHttpService} from "../../shared/services/http/cart-http.service";
import {finalize} from "rxjs/operators";
import {CartSummaryEntity} from "../../shared/entities/cart-summary.entity";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  public ordersError$: Subject<ApiErrorEntity> = new Subject<ApiErrorEntity>();
  private _orders$: Subject<OrdersPageEntity> = new Subject<OrdersPageEntity>();
  public orders$: Observable<OrdersPageEntity> = this._orders$.asObservable();
  public ordersLoading$: Subject<boolean> = new Subject<boolean>();

  public orderDetailsError$: Subject<ApiErrorEntity> = new Subject<ApiErrorEntity>();
  private _orderDetails$: Subject<OrderDetailsEntity> = new Subject<OrderDetailsEntity>();
  public orderDetails$: Observable<OrderDetailsEntity> = this._orderDetails$.asObservable();
  public orderDetailsLoading$: Subject<boolean> = new Subject<boolean>();

  public updateOrderFavouritismError$: Subject<ApiErrorEntity> = new Subject<ApiErrorEntity>();
  public updateOrderFavouritismSuccess$: Subject<boolean> = new Subject<boolean>();
  public updatingOrderFavouritism$: Subject<boolean> = new Subject<boolean>();

  private _cartSummary$: Subject<CartSummaryEntity> = new Subject<CartSummaryEntity>();
  public cartSummary$: Observable<CartSummaryEntity> = this._cartSummary$.asObservable();

  public reorderLoading$: Subject<number | null> = new Subject<number | null>();
  public reorderError$: Subject<ApiErrorEntity> = new Subject<ApiErrorEntity>();
  public reorderSuccess$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private ordersHttpService: OrdersHttpService,
    private downloadFileService: DownloadFileService,
    private cartHttpService: CartHttpService,
    private toastrService: ToastrService,
    private translateService: TranslateService
  ) {
  }

  public loadOrders(filters: OrdersFilterEntity): void {
    this.ordersHttpService
      .loadOrders(filters)
      .pipe(
        debounceTime(200),
        indicate(this.ordersLoading$),
        catchError((errorResponse) => {
          this.ordersError$.next(errorResponse);
          return EMPTY;
        }),
        take(1)
      )
      .subscribe((ordersEntities) => {
        this._orders$.next(ordersEntities);
      });
  }

  public loadOrderDetails(orderId: number): void {
    this.ordersHttpService
      .loadOrderDetails(orderId)
      .pipe(
        indicate(this.orderDetailsLoading$),
        catchError((errorResponse) => {
          this.orderDetailsError$.next(errorResponse);
          return EMPTY;
        }),
        take(1)
      )
      .subscribe((orderDetailsEntity) => {
        this._orderDetails$.next(orderDetailsEntity);
      });
  }

  public addOrderToFavourites(orderId: number): void {
    this.ordersHttpService
      .addOrderToFavourites(orderId)
      .pipe(
        indicate(this.updatingOrderFavouritism$),
        catchError((errorResponse) => {
          this.updateOrderFavouritismError$.next(errorResponse);
          return EMPTY;
        }),
        take(1)
      )
      .subscribe((orderDetailsEntity) => {
        this._orderDetails$.next(orderDetailsEntity);
        this.updateOrderFavouritismSuccess$.next(true);
      });
  }

  public removeOrderToFavourites(orderId: number): void {
    this.ordersHttpService
      .removeOrderFromFavourites(orderId)
      .pipe(
        indicate(this.updatingOrderFavouritism$),
        catchError((errorResponse) => {
          this.updateOrderFavouritismError$.next(errorResponse);
          return EMPTY;
        }),
        take(1)
      )
      .subscribe((orderDetailsEntity) => {
        this._orderDetails$.next(orderDetailsEntity);
        this.updateOrderFavouritismSuccess$.next(true);
      });
  }

  public getOrderInvoice(orderId: number): void {
    this.ordersHttpService
      .getOrderInvoice(orderId)
      .pipe(
        map((data) => {
          return this.downloadFileService.downloadFileByResponseData(data);
        })
      )
      .pipe(take(1))
      .subscribe();
  }

  public reorderWholeOrder(orderId: number): void {
    this.reorderLoading$.next(0);
    this.ordersHttpService
      .reorderWholeOrder(orderId)
      .pipe(
        concatMap(() => {
          return this.cartHttpService.loadCartSummary();
        }),
        catchError((errorResponse) => {
          this.reorderError$.next(errorResponse);
          this.toastrService.error(this.translateService.instant('PANEL.TOAST.CART.FULL'));
          return EMPTY;
        }),
        take(1),
        finalize(() => this.reorderLoading$.next(null))
      )
      .subscribe((cartSummaryEntity) => {
        this._cartSummary$.next(cartSummaryEntity);
        this.reorderSuccess$.next(true);

        this.toastrService.success(this.translateService.instant('PANEL.TOAST.CART.ORDER_ADDED'));
      });
  }

  public reorderSingleItem(orderId: number, orderItemId: number): void {
    this.reorderLoading$.next(orderItemId);
    this.ordersHttpService
      .reorderSingleItem(orderId, orderItemId)
      .pipe(
        concatMap(() => {
          return this.cartHttpService.loadCartSummary();
        }),
        catchError((errorResponse) => {
          this.reorderError$.next(errorResponse);
          this.toastrService.error(this.translateService.instant('PANEL.TOAST.CART.FULL'));
          return EMPTY;
        }),
        take(1),
        finalize(() => this.reorderLoading$.next(null))
      )
      .subscribe((cartSummaryEntity) => {
        this._cartSummary$.next(cartSummaryEntity);
        this.reorderSuccess$.next(true);

        this.toastrService.success(this.translateService.instant('PANEL.TOAST.CART.ORDER_ITEM_ADDED'));
      });
  }
}
