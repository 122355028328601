export interface ApiError {
  error: string;
  errors: { [key: string]: string[] };
  genericErrors: string[];
  validation: string;
}

export class ApiErrorEntity {
  constructor(
    public errors: { [key: string]: string[] },
    public genericErrors: string[]) {
  }

  public static fromData(data: ApiError): ApiErrorEntity {
    if (data?.validation) {
      return new ApiErrorEntity({validation: [data.validation]},
        [data.validation]);
    }

    if (data?.errors) {
      return new ApiErrorEntity(data?.errors, data?.genericErrors);
    } else {
      return new ApiErrorEntity(data?.errors, [data.error]);
    }
  }
}
