import {Component, Input} from '@angular/core';
import {OrderStatus} from "../../enums/order-status.enum";
import {OrderEntity} from "../../entities/order.entity";
import {OrderDetailsEntity} from "../../entities/order-details.entity";

@Component({
  selector: 'app-order-status-display',
  templateUrl: './order-status-display.component.html',
  styleUrls: ['./order-status-display.component.scss']
})
export class OrderStatusDisplayComponent {

  @Input()
  orderItem!: OrderEntity | OrderDetailsEntity;

  protected readonly OrderStatus = OrderStatus;
}
