import {Component, Input, OnInit} from '@angular/core';
import {Icon} from "../../../enums/icon.enum";
import {RowEvent} from "../row-event";
import {Subject, takeUntil} from "rxjs";
import {BaseComponent} from "../../base/base.component";
import {ButtonDialogComponent} from "../../button-dialog/button-dialog.component";
import {DialogService} from "@ngneat/dialog";
import {BottomMenuService} from "../../../../app/layout/menu/services/bottom-menu.service";
import {ExtendedTableService} from "../services/extended-table.service";
import {MobileActionType} from "../../../enums/mobile-action-type.enum";

@Component({
  selector: 'app-actions-cell',
  templateUrl: './actions-cell.component.html',
  styleUrls: ['./actions-cell.component.scss']
})
export class ActionsCellComponent extends BaseComponent implements OnInit {

  @Input()
  element: any;

  @Input()
  rowNumber!: number;

  @Input()
  rowEvents!: RowEvent[];

  @Input()
  mobileActionType?: MobileActionType;

  @Input()
  isExpandable = false;

  public showSubmenusDialog$: Subject<[any, RowEvent[]]> = new Subject<[any, RowEvent[]]>();
  public toggleRowVisibility$: Subject<void> = new Subject<void>();

  public isExpanded = false;

  constructor(
    private dialogService: DialogService,
    private bottomMenuService: BottomMenuService,
    private extendedTableService: ExtendedTableService
  ) {
    super();
  }

  ngOnInit(): void {
    this.showSubmenusDialog$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([entity, rowEvents]) => {
        const enabledRowEvents = rowEvents.filter(r => r.showCondition == null || r.showCondition(entity) === true);

        this.bottomMenuService.hide();
        this.dialogService.open(ButtonDialogComponent, {
          id: 'submenus-dialog',
          closeButton: false,
          width: 'auto',
          data: {
            entity: entity,
            items: enabledRowEvents
          }
        });
      });

    this.toggleRowVisibility$.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (this.isExpanded) {
          this.extendedTableService.rowExpanded$.next(null);
          this.isExpanded = false;
        } else {
          this.extendedTableService.rowExpanded$.next(this.rowNumber);
          this.isExpanded = true;
        }
      });

    this.extendedTableService.rowExpanded$.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe((rowNumber) => {
        if (rowNumber) {
          this.isExpanded = rowNumber === this.rowNumber;
        } else {
          this.isExpanded = false;
        }
      });
  }

  protected readonly Icon = Icon;
  protected readonly MobileActionType = MobileActionType;
}
