import {Component, OnInit} from '@angular/core';
import {Icon} from "../../shared/enums/icon.enum";
import {Subject, takeUntil} from "rxjs";
import {BaseComponent} from "../../shared/components/base/base.component";
import {ApiUrlsProvider} from "../../shared/utils/api-url-provider";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit {

  public continueShopping$: Subject<void> = new Subject<void>();

  constructor(
    public apiUrlsProvider: ApiUrlsProvider
  ) {
    super();
  }

  ngOnInit(): void {
    this.continueShopping$.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(() => {
        window.location.href = this.apiUrlsProvider.homeUrl();
      });
  }

  protected readonly Icon = Icon;
}
