import { Injectable } from '@angular/core';
import {FilterKey} from "../../../shared/enums/filter-key.enum";
import {OrdersFilterEntity} from "../../../shared/entities/orders-filter.entity";
import {OrdersService} from "../../services/orders.service";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class OrdersTableService {

  public ordersTableFiltersReset$: Subject<void> = new Subject<void>();
  public onlyFavourite = false;
  public dateFrom: Date | null = null;
  public dateTo: Date = new Date();

  private filters = OrdersFilterEntity.empty();

  constructor(
    private ordersService: OrdersService,
  ) {
  }

  public setFilter(filterKey: FilterKey, filterValue: any) {
    switch (filterKey) {
      case FilterKey.Page:
        this.filters.page = filterValue;
        break;
      case FilterKey.PerPage:
        this.filters.per_page = filterValue;
        break;
      case FilterKey.SortedBy:
        this.filters.sorted_by = filterValue;
        break;
    }

    this.ordersService.loadOrders(this.filters);
  }

  public setFilterWithPageReset(filterKey: FilterKey, filterValue: any) {
    this.filters.page = 1;

    switch (filterKey) {
      case FilterKey.FilteredByCode:
        this.filters.filtered_by_code = filterValue;
        break;
      case FilterKey.FilteredByIsFavourite:
        this.onlyFavourite = filterValue;
        this.filters.filtered_by_is_favourite = filterValue;
        break;
      case FilterKey.FilteredByCreatedAtFrom:
        this.dateFrom = filterValue;
        this.filters.filtered_by_created_at_from = filterValue;
        break;
      case FilterKey.FilteredByCreatedAtTo:
        this.dateTo = filterValue;
        this.filters.filtered_by_created_at_to = filterValue;
        break;
    }

    this.ordersService.loadOrders(this.filters);
  }

  public resetFilters(): void {
    this.ordersTableFiltersReset$.next();
    this.filters = OrdersFilterEntity.empty();
    this.ordersService.loadOrders(this.filters);
  }
}
