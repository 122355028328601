<div
  class="flex-column m-medium"
  *ngIf="invoiceAddress"
>
  <p class="xs semi mb-small">{{ 'PANEL.ADDRESS.INVOICE' | translate }}</p>
  <p class="s">{{ invoiceAddress.fullName }}</p>
  <p class="s">
    {{ 'PANEL.ADDRESS.STREET' | translate }} {{ invoiceAddress.street }}
    {{ invoiceAddress.houseNo }}
  </p>
  <p class="s">{{ invoiceAddress.postCode }}, {{ invoiceAddress.city }}</p>
  <p class="s">{{ invoiceAddress.country }}</p>
  <p class="s">{{ 'PANEL.ADDRESS.VAT_NUMBER' | translate }} {{ invoiceAddress.vatId }}</p>
</div>
