import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'trim'
})
export class TrimPipe implements PipeTransform {
  transform(value: any, maxLength: number): string {
    const text = value.toString();
    const textLength = text.length;

    if (textLength <= maxLength) {
      return value;
    }

    return text.substring(0, maxLength - 3).concat('...');
  }
}
