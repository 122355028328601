import {Component, OnInit} from '@angular/core';
import {FormService} from '../../services/form.service';
import {BehaviorSubject, Subject, takeUntil} from 'rxjs';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../shared/services/auth.service';
import {ApiErrorEntity} from '../../../shared/entities/api-error.entity';
import {ApiUrlsProvider} from "../../../shared/utils/api-url-provider";
import {FormsService} from "../../../shared/services/forms.service";
import {Router} from "@angular/router";
import {Icon} from "../../../shared/enums/icon.enum";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit {

  public passwordForgotten$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public submit$ = new Subject<[Event, FormGroup]>();

  public loginSuccess$: Subject<void> = new Subject<void>();
  public loginError$: Subject<ApiErrorEntity> = new Subject<ApiErrorEntity>();

  public authServiceLoading$: Subject<boolean> = new Subject<boolean>();

  public form: FormGroup;
  public emailControl = new FormControl('', [Validators.required, Validators.email, Validators.minLength(2), Validators.maxLength(128)]);
  public passwordControl = new FormControl('', [Validators.required, Validators.minLength(8)]);

  public errorMessage!: string[];

  constructor(
    private authService: AuthService,
    private formService: FormService,
    private formBuilder: FormBuilder,
    private apiUrlsProvider: ApiUrlsProvider,
    private formsService: FormsService,
    private router: Router
  ) {
    super();

    this.form = formBuilder.group({
      emailControl: this.emailControl,
      passwordControl: this.passwordControl
    });
  }

  ngOnInit(): void {
    this.initReactiveBindings();

    this.submit$.pipe(takeUntil(this.unsubscribe$)).subscribe(([event, form]) => this.submitForm(event, form));

    this.loginSuccess$.pipe().subscribe(() => {
      const backUrl = this.apiUrlsProvider.backUrl$.value;
      if(backUrl){
        window.location.href = backUrl;
      } else {
        this.router.navigate(['home']);
      }
    });

    this.loginError$.pipe(takeUntil(this.unsubscribe$)).subscribe((errorEntity) => {
      this.errorMessage = this.formsService.handleRemoteErrors(errorEntity, this.form);
    });
  }

  public submitForm(event: Event, form: FormGroup): void {
    event.preventDefault();

    if (form.valid) {
      this.authService.login(this.emailControl.value!, this.passwordControl.value!);
    }
  }

  private initReactiveBindings(): void {
    this.bind(this.passwordForgotten$, this.formService.passwordForgotten$);
    this.bind(this.authService.loginSuccess$, this.loginSuccess$);
    this.bind(this.authService.loginError$, this.loginError$);
    this.bind(this.authService.authServiceLoading$, this.authServiceLoading$);
  }

  protected readonly Icon = Icon;
}
