<ng-container *ngIf="paginator && pageEntity && pageEntity.count !== 0">
  <div class="paginator">
    <div class="items-per-page">
      <p class="s">
        {{ 'GENERAL.TABLE.PAGINATOR.PER_PAGE' | translate }}
      </p>

      <ng-container *ngIf="!editablePageSize">
        <p class="s ml-tiny mr-tiny bold">
          {{pageEntity.perPage}}
        </p>
      </ng-container>
      <ng-container *ngIf="editablePageSize">
        <ng-container *ngFor="let pageSize of pageSizes">
          <p class="s ml-tiny mr-tiny"
             [ngClass]="pageEntity.perPage === pageSize ? 'bold' : 'underlined'"
             (click)="changePerPage$.next(pageSize)">
            {{pageSize}}
          </p>
        </ng-container>
      </ng-container>
    </div>

    <div class="page-options">
      <button
        (click)="changePage$.next(pageEntity.page - 1)"
        [disabled]="pageEntity.page === 1"
      >
        <app-icon
          [icon]="Icon.ChevronLeft"
          [color]="'#FFFFFF'"
        ></app-icon>
      </button>

      <p class="s center ml-medium mr-medium">
        {{ 'GENERAL.TABLE.PAGINATOR.PAGE' | translate }}
        {{ pageEntity.count !== 0 ? pageEntity.page : 0 }}
        {{ 'GENERAL.TABLE.PAGINATOR.OF' | translate }}
        {{ pageEntity.pages }}
      </p>

      <button
        (click)="changePage$.next(pageEntity.page + 1)"
        [disabled]="pageEntity.page === pageEntity.pages"
      >
        <app-icon
          [icon]="Icon.ChevronRight"
          [color]="'#FFFFFF'"
        ></app-icon>
      </button>
    </div>

    <div class="total-items">
      <p class="s">{{ 'GENERAL.TABLE.PAGINATOR.TOTAL' | translate }} {{ pageEntity.count }}</p>
    </div>
  </div>
</ng-container>
<!--<div
  class="flex-row space-between center mt-big mb-medium"
  *ngIf="paginator && pageEntity && pageEntity.count !== 0"
>
  <div class="flex-row center">
    <p class="s">
      {{ 'GENERAL.TABLE.PAGINATOR.PER_PAGE' | translate }}
    </p>

    <ng-container *ngIf="!editablePageSize">
      <p class="ml-tiny mr-tiny bold">
        {{pageEntity.perPage}}
      </p>
    </ng-container>
    <ng-container *ngIf="editablePageSize">
      <ng-container *ngFor="let pageSize of pageSizes">
        <p class="ml-tiny mr-tiny"
           [class.bold]="pageEntity.perPage === pageSize"
           (click)="changePerPage$.next(+pageSize)">
          {{pageSize}}
        </p>
      </ng-container>
    </ng-container>
  </div>

  <div class="flex-row center">
    <button
      (click)="changePage$.next(pageEntity.page - 1)"
      [disabled]="pageEntity.page === 1"
    >
      <app-icon
        [icon]="Icon.ChevronLeft"
        [color]="'#FFFFFF'"
      ></app-icon>
    </button>

    <p class="s center ml-medium mr-medium">
      {{ 'GENERAL.TABLE.PAGINATOR.PAGE' | translate }}
      {{ pageEntity.count !== 0 ? pageEntity.page : 0 }}
      {{ 'GENERAL.TABLE.PAGINATOR.OF' | translate }}
      {{ pageEntity.pages }}
    </p>

    <button
      (click)="changePage$.next(pageEntity.page + 1)"
      [disabled]="pageEntity.page === pageEntity.pages"
    >
      <app-icon
        [icon]="Icon.ChevronRight"
        [color]="'#FFFFFF'"
      ></app-icon>
    </button>
  </div>

  <p class="s right">{{ 'GENERAL.TABLE.PAGINATOR.TOTAL' | translate }} {{ pageEntity.count }}</p>
</div>-->
