import {Injectable} from '@angular/core';
import {ApiErrorEntity} from "../entities/api-error.entity";
import {FormGroup} from "@angular/forms";
import {NewApiErrorEntity} from "../entities/new-api-error.entity";

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  constructor() {
  }

  public handleRemoteErrors(error: ApiErrorEntity, submittedForm?: FormGroup): string[] {

    if (!error?.errors) {
      return error?.genericErrors;
    }

    for (const key of Object.keys(error.errors)) {
      const message = error.errors[key].join('<br>');
      if (submittedForm?.controls[key + 'Control'] != null) {
        submittedForm.controls[key + 'Control'].setErrors({remote: message}, {emitEvent: true});
        submittedForm.controls[key + 'Control'].markAsDirty();
      }
    }
    return error.genericErrors;
  }

  public handleNewRemoteErrors(error: NewApiErrorEntity, submittedForm?: FormGroup) {
    for (const key of Object.keys(error.validation)) {
      const message = error.validation[key].join('<br>');
      const controlName = this.prepareControlName(key);

      if (submittedForm?.controls[controlName] != null) {
        submittedForm.controls[controlName].setErrors({remote: this.capitalizeFirstLetter(message)}, {emitEvent: true});
        submittedForm.controls[controlName].markAsDirty();
      }
    }
  }

  private prepareControlName(key: string): string {
    if (key.indexOf('_') === -1) {
      return key + 'Control';
    }

    const split = key.split('_');
    return split[0].concat(this.capitalizeFirstLetter(split[1]), 'Control');
  }

  private capitalizeFirstLetter(sentence: string): string {
    return sentence.charAt(0).toUpperCase() + sentence.slice(1);
  }
}
