<div class="container">
  <h2 class="mb-big">{{ 'FTL.FORMS.LOGIN.HEADER' | translate }}</h2>

  <p
    class="text-error mb-big"
    *ngIf="errorMessage"
  >
    {{ errorMessage }}
  </p>

  <form
    [formGroup]="form"
    (ngSubmit)="submit$.next([$event, form])"
  >
    <app-extended-input
      formControlName="emailControl"
      [icon]="Icon.Envelop"
      [label]="'FTL.FORMS.LOGIN.LABEL_EMAIL' | translate"
      [placeholder]="'FTL.FORMS.LOGIN.PLACEHOLDER_EMAIL' | translate"
      [validationErrors]="[
          {
            key: 'required',
            value: 'FTL.FORMS.VALIDATION.EMAIL.REQUIRED' | translate
          },
          { key: 'email', value: 'FTL.FORMS.VALIDATION.EMAIL.EMAIL' | translate },
          {
            key: 'minlength',
            value: 'FTL.FORMS.VALIDATION.EMAIL.MINLENGTH' | translate
          },
          {
            key: 'maxlength',
            value: 'FTL.FORMS.VALIDATION.EMAIL.MAXLENGTH' | translate
          }
        ]"
      type="email"
    ></app-extended-input>

    <div class="mt-medium">
      <app-extended-input
        formControlName="passwordControl"
        [icon]="Icon.Key"
        [label]="'FTL.FORMS.LOGIN.LABEL_PASSWORD' | translate"
        [placeholder]="'FTL.FORMS.LOGIN.PLACEHOLDER_PASSWORD' | translate"
        [visiblePassword]="true"
        [validationErrors]="[
            {
              key: 'required',
              value: 'FTL.FORMS.VALIDATION.PASSWORD.REQUIRED' | translate
            },
            {
              key: 'minlength',
              value: 'FTL.FORMS.VALIDATION.PASSWORD.MINLENGTH' | translate
            }
          ]"
        type="password"
      ></app-extended-input>
    </div>

    <div class="mt-big">
      <app-extended-button
        class="filled wide mt-big"
        [text]="'FTL.FORMS.LOGIN.BUTTON_LOG_IN' | translate"
        [disabled]="form.invalid || (authServiceLoading$ | async)!"
      ></app-extended-button>
    </div>

    <div class="mt-tiny">
      <app-extended-button
        class="link wide medium"
        [text]="'FTL.FORMS.LOGIN.BUTTON_FORGOTTEN_PASSWORD' | translate"
        (click)="passwordForgotten$.next(true)"
      ></app-extended-button>
    </div>
  </form>
</div>
