export enum RoutePath {
  Empty = '',
  Login = 'login',
  SetPassword = 'set-password',
  Home = 'home',
  Designs = 'designs',
  DesignsOrdered = 'ordered',
  DesignsFavourite = 'favourite',
  Orders = 'orders',
  OrderDetails = ':orderId',
  Account = 'account'
}
