<form
  [formGroup]="form"
  class="flex-column center"
  novalidate
>
  <p
    id="language-label"
    class="xxs semi mt-big mb-tiny"
  >
    {{ 'GENERAL.LANGUAGE' | translate }}
  </p>

  <ng-select
    class="custom"
    appearance="outline"
    [ngClass]="'mobile'"
    [searchable]="false"
    [clearable]="false"
    formControlName="languageControl"
  >
    <ng-option
      *ngFor="let language of languages"
      value="{{ language.key }}"
    >
      <div class="flex-row center">
        <img
          src="{{ language.icon }}"
          height="24px"
        />
        <p class="s ml-small">{{ language.value }}</p>
      </div>
    </ng-option>
  </ng-select>
</form>
