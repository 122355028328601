import {Component, Input, OnInit} from '@angular/core';
import {Icon} from "../../../../enums/icon.enum";
import {Subject, takeUntil} from "rxjs";
import {BaseComponent} from "../../../base/base.component";
import {ColumnDefinition} from "../../column-definition";
import {ExtendedTableService} from "../../services/extended-table.service";

@Component({
  selector: 'app-sort-button',
  templateUrl: './sort-button.component.html'
})
export class SortButtonComponent extends BaseComponent implements OnInit {

  @Input()
  columnDefinition!: ColumnDefinition;

  public onSortDirectionChanged$: Subject<boolean> = new Subject<boolean>();

  public sortDirection!: boolean;
  public justSorted = false;

  constructor(
    private extendedTableService: ExtendedTableService
  ) {
    super();
  }

  ngOnInit(): void {
    this.onSortDirectionChanged$.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe((sortDirection) => {
        this.extendedTableService.sortingChanged$.next({
          fieldName: this.columnDefinition.fieldName,
          sortDirection: sortDirection
        });
      });

    this.extendedTableService.sortingChanged$.pipe()
      .subscribe((sortEvent) => {
        if (this.columnDefinition.fieldName === sortEvent.fieldName) {
          this.justSorted = true;
          this.sortDirection = sortEvent.sortDirection;
        } else {
          this.justSorted = false;
        }
      });
  }

  protected readonly Icon = Icon;
}
