import {Component, OnInit} from '@angular/core';
import {DialogRef} from "@ngneat/dialog";
import {ButtonDialogItem} from "../../interfaces/button-dialog-item.interface";
import {Subject, takeUntil} from "rxjs";
import {BaseComponent} from "../base/base.component";
import {BottomMenuService} from "../../../app/layout/menu/services/bottom-menu.service";

@Component({
  selector: 'app-button-dialog',
  templateUrl: './button-dialog.component.html',
  styleUrls: ['./button-dialog.component.scss']
})
export class ButtonDialogComponent extends BaseComponent implements OnInit {

  public executeItemAction$: Subject<ButtonDialogItem> = new Subject<ButtonDialogItem>();
  public closeDialog$: Subject<void> = new Subject<void>();

  public entity: any;
  public items!: ButtonDialogItem[];

  constructor(
    public dialogRef: DialogRef,
    private bottomMenuService: BottomMenuService
  ) {
    super();
  }

  ngOnInit(): void {
    const data = this.dialogRef.data;
    this.entity = data.entity;
    this.items = data.items;

    this.executeItemAction$.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe((buttonDialogItem) => {
        buttonDialogItem.func?.next(this.entity === undefined ? buttonDialogItem : this.entity);
        this.dialogRef!.close();
      });

    this.closeDialog$.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.bottomMenuService.show();
        this.dialogRef!.close();
      });
  }
}
