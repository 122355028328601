<div class="content-element">
  <div class="flex-row wrap space-between center">
    <div class="flex-row center grow">
      <ng-container *ngIf="cartSummary$ | async as cartSummary">
        <app-icon
          [icon]="Icon.Cart"
          class="mr-medium"
        ></app-icon>
        <p
          class="s"
          [innerHTML]="
            'PANEL.LAYOUT.CONTENT.CART_LINK.CART_CONTENT'
              | translate
                : {
                    cartSummary:
                      cartSummary.totalQuantity
                      | i18nPlural : cartItemsMap
                      | translate
                        : {
                            quantity: cartSummary.totalQuantity | number : '1.0-0'
                          },
                    goToCart: cartSummary.totalQuantity !== 0 ? ('PANEL.LAYOUT.CONTENT.CART_LINK.GO_TO_CART' | translate) : ''
                  }
              | bold
          "
        ></p>
      </ng-container>
    </div>

    <app-extended-button
      class="filled grow cart"
      [text]="'PANEL.LAYOUT.CONTENT.CART_LINK.SEE_CART_BUTTON' | translate"
      (clicked)="redirectToCart$.next()"
    ></app-extended-button>
  </div>
</div>
