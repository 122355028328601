<button
  class="flex-row center"
  [disabled]="loading || disabled"
  (click)="clicked.next()"
>
  <app-icon
    *ngIf="icon"
    [icon]="icon"
    [clickable]="false"
    class="mr-small"
  ></app-icon>
  <app-loading-indicator
    *ngIf="loading"
    [loadingIndicatorType]="LoadingIndicatorType.Button">
  </app-loading-indicator>
  <p class="xs">{{ text }}</p>
</button>
