import {Injectable} from '@angular/core';
import {map, Observable} from "rxjs";
import {ApiPath} from "../../enums/api-path.enum";
import {CartSummary, CartSummaryEntity} from "../../entities/cart-summary.entity";
import {ApiUrlsProvider} from "../../utils/api-url-provider";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class CartHttpService {

  constructor(
    private apiUrlProvider: ApiUrlsProvider,
    private http: HttpClient
  ) {
  }

  public loadCartSummary(): Observable<CartSummaryEntity> {
    const url = this.apiUrlProvider.apiLanguageUrl(ApiPath.GetCartSummary);

    return this.http.get<CartSummary>(url).pipe(map((response) => CartSummaryEntity.fromData(response)));
  }
}
