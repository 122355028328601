<div class="flex-row center-v wrap">
  <ng-container *ngIf="(isPasswordForgotten$ | async) === false">
    <app-login></app-login>
  </ng-container>

  <ng-container *ngIf="(isPasswordForgotten$ | async) === true">
    <app-reset-password></app-reset-password>
  </ng-container>

  <app-registration></app-registration>
</div>
