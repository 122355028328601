import {Injectable} from '@angular/core';
import {catchError, EMPTY, Observable, ReplaySubject, Subject, take} from "rxjs";
import {AccountHttpService} from "../../shared/services/http/account-http.service";
import {ApiErrorEntity} from "../../shared/entities/api-error.entity";
import {Account, AccountEntity} from "../../shared/entities/account.entity";
import {InvoiceAddressEntity} from "../../shared/entities/invoice-address.entity";
import {ShippingAddressEntity} from "../../shared/entities/shipping-addres.entity";
import {NewApiErrorEntity} from "../../shared/entities/new-api-error.entity";

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  public accountDataUpdateSuccess$: Subject<void> = new Subject<void>();
  public accountDataUpdateError$: Subject<ApiErrorEntity> = new Subject<ApiErrorEntity>();

  public passwordChangeSuccess$: Subject<boolean> = new Subject<boolean>();
  public passwordChangeError$: Subject<NewApiErrorEntity> = new Subject<NewApiErrorEntity>();

  private _accountData$: ReplaySubject<AccountEntity> = new ReplaySubject<AccountEntity>();
  public accountData$: Observable<AccountEntity> = this._accountData$.asObservable();
  public accountDataSuccess$: Subject<void> = new Subject<void>();
  public accountDataError$: Subject<ApiErrorEntity> = new Subject<ApiErrorEntity>();

  private _invoiceAddress$: Subject<InvoiceAddressEntity> = new Subject<InvoiceAddressEntity>();
  public invoiceAddress$: Observable<InvoiceAddressEntity> = this._invoiceAddress$.asObservable();
  public invoiceAddressError$: Subject<ApiErrorEntity> = new Subject<ApiErrorEntity>();

  private _shippingAddress$: Subject<ShippingAddressEntity> = new Subject<ShippingAddressEntity>();
  public shippingAddress$: Observable<ShippingAddressEntity> = this._shippingAddress$.asObservable();
  public shippingAddressError$: Subject<ApiErrorEntity> = new Subject<ApiErrorEntity>();

  constructor(
    private accountHttpService: AccountHttpService
  ) {
  }

  public loadAccountData(): void {
    this.accountHttpService
      .getAccountData()
      .pipe(
        catchError((errorResponse) => {
          this.accountDataError$.next(errorResponse);
          return EMPTY;
        }),
        take(1)
      )
      .subscribe((accountData) => {
        this._accountData$.next(accountData);
      });
  }

  public updateAccountData(accountData: Partial<Account>): void {
    this.accountHttpService
      .updateAccountData(accountData)
      .pipe(
        catchError((error) => {
          this.accountDataUpdateError$.next(ApiErrorEntity.fromData(error.error));
          return EMPTY;
        }),
        take(1)
      )
      .subscribe((accountData) => {
        this._accountData$.next(accountData);
        this.accountDataUpdateSuccess$.next();
      });
  }

  public changePassword(currentPassword: string, password: string, passwordConfirmation: string): void {
    this.accountHttpService
      .changePassword(currentPassword, password, passwordConfirmation)
      .pipe(
        catchError((error) => {
          this.passwordChangeError$.next(NewApiErrorEntity.fromData(error.error));
          return EMPTY;
        }),
        take(1)
      )
      .subscribe(() => {
        this.passwordChangeSuccess$.next(true);
      });
  }

  public getInvoiceAddress(): void {
    this.accountHttpService
      .getInvoiceAddress()
      .pipe(
        catchError((errorResponse) => {
          this.invoiceAddressError$.next(errorResponse);
          return EMPTY;
        }),
        take(1)
      )
      .subscribe((invoiceAddressEntity) => {
        this._invoiceAddress$.next(invoiceAddressEntity);
      });
  }

  public getShippingAddress(): void {
    this.accountHttpService
      .getShippingAddress()
      .pipe(
        catchError((errorResponse) => {
          this.shippingAddressError$.next(errorResponse);
          return EMPTY;
        }),
        take(1)
      )
      .subscribe((shippingAddressEntity) => {
        this._shippingAddress$.next(shippingAddressEntity);
      });
  }
}
