import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PageEntity} from "../../../entities/page.entity";
import {Subject, takeUntil} from "rxjs";
import {BaseComponent} from "../../base/base.component";
import {Icon} from "../../../enums/icon.enum";
import {PageEvent} from "./page-event";

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent extends BaseComponent implements OnInit {
  @Input()
  paginator = true;

  @Input() set pageEntity(pageEntity: PageEntity<any>) {
    this._pageEntity = pageEntity;
    this.pageEvent = {currentPage: pageEntity?.page, nextPage: 1, currentPageSize: pageEntity?.perPage};
  }

  get pageEntity(): PageEntity<any> {
    return this._pageEntity;
  }

  @Input()
  editablePageSize = false;

  @Output()
  paginationChanged: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();

  public changePage$: Subject<number> = new Subject<number>();
  public changePerPage$: Subject<number> = new Subject<number>();
  public changePagination$: Subject<PageEvent> = new Subject<PageEvent>();

  public pageEvent!: PageEvent;

  private _pageEntity!: PageEntity<any>;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.changePage$.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe((nextPageValue) => {
          this.pageEvent.nextPage = nextPageValue;
          this.changePagination$.next(this.pageEvent);
        }
      );

    this.changePerPage$.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe((perPageValue) => {
          this.pageEvent.nextPage = 1;
          this.pageEvent.currentPageSize = perPageValue;
          this.changePagination$.next(this.pageEvent);
        }
      );

    this.changePagination$.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe((pageEvent) => {
          this.paginationChanged.next(pageEvent);
        }
      );
  }

  public pageSizes: number[] = [10, 25, 50];

  protected readonly Icon = Icon;
}
