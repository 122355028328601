<div class="flex-column space-between p-medium">
  <div>
    <p class="xs semi mb-small">{{ 'PANEL.ORDERS.DETAILS.ORDER_COST' | translate }}</p>
    <p class="s">{{ orderDetails.totalValue }} {{ orderDetails.currency | uppercase }}</p>
  </div>

  <div class="flex-column space-between">
    <ng-container *ngIf="orderDetails.shippingNumbers.length">
      <p class="s bold">{{ 'PANEL.ORDERS.DETAILS.TRACKING_NUMBER' | translate }}</p>
      <ng-container *ngFor="let shippingNumber of orderDetails.shippingNumbers">
        <div class="flex-row">
          <p class="s mr-tiny">{{ shippingNumber }}</p>
          <app-icon [cdkCopyToClipboard]="shippingNumber.toString()" [icon]="Icon.Copy"></app-icon>
        </div>
      </ng-container>
      <a href="{{ orderDetails.courierCompanyUrl }}">
        <p class="s">
          {{ 'PANEL.ORDERS.DETAILS.TRACK_YOUR_PARCEL' | translate }}
        </p>
      </a>
    </ng-container>

  </div>
</div>
