import {Injectable} from '@angular/core';
import {catchError, EMPTY, Observable, Subject, take} from "rxjs";
import {CartHttpService} from "../../shared/services/http/cart-http.service";
import {ApiErrorEntity} from "../../shared/entities/api-error.entity";
import {CartSummaryEntity} from "../../shared/entities/cart-summary.entity";

@Injectable({
  providedIn: 'root'
})
export class CartService {

  public cartSummaryError$: Subject<ApiErrorEntity> = new Subject<ApiErrorEntity>();

  private _cartSummary$: Subject<CartSummaryEntity> = new Subject<CartSummaryEntity>();
  public cartSummary$: Observable<CartSummaryEntity> = this._cartSummary$.asObservable();

  constructor(
    private cartHttpService: CartHttpService
  ) {
  }

  public loadCartSummary(): void {
    this.cartHttpService
      .loadCartSummary()
      .pipe(
        catchError((errorResponse) => {
          this.cartSummaryError$.next(errorResponse);
          return EMPTY;
        }),
        take(1)
      )
      .subscribe((cartSummary) => {
        this._cartSummary$.next(cartSummary);
      });
  }
}
