import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Language} from "../../../shared/enums/language.enum";
import {TranslateService} from "@ngx-translate/core";
import {ApiUrlsProvider} from "../../../shared/utils/api-url-provider";
import {BaseComponent} from "../../../shared/components/base/base.component";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-language-switch',
  templateUrl: './language-switch.component.html'
})
export class LanguageSwitchComponent extends BaseComponent implements OnInit {

  public accountUrlPrepared$: Subject<string> = new Subject<string>();

  public form: FormGroup;
  public languageControl = new FormControl();

  constructor(
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private apiUrlsProvider: ApiUrlsProvider
  ) {
    super();
    this.form = formBuilder.group({
      languageControl: this.languageControl
    });
  }

  ngOnInit(): void {
    this.initReactiveBinding();

    this.languageControl.setValue(this.translateService.currentLang);

    this.languageControl.valueChanges
      .pipe()
      .subscribe((newLanguage) => {
        this.apiUrlsProvider.prepareLanguageAccountUrl(newLanguage);
      });

    this.accountUrlPrepared$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(accountUrl => {
        window.location.assign(accountUrl);
      });
  }

  public languages = [
    {
      key: Language.Polish,
      value: 'PL',
      icon: `${this.apiUrlsProvider.clientAppBaseUrl()}/assets/images/flags/poland.svg`
    },
    {
      key: Language.English,
      value: 'EN',
      icon: `${this.apiUrlsProvider.clientAppBaseUrl()}/assets/images/flags/united_kingdom.svg`
    },
    {
      key: Language.German,
      value: 'DE',
      icon: `${this.apiUrlsProvider.clientAppBaseUrl()}/assets/images/flags/germany.svg`
    },
    {
      key: Language.French,
      value: 'FR',
      icon: `${this.apiUrlsProvider.clientAppBaseUrl()}/assets/images/flags/france.svg`
    },
  ];

  private initReactiveBinding(): void {
    this.bind(this.apiUrlsProvider.accountUrlPrepared$, this.accountUrlPrepared$);
  }
}
