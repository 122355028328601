import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MenuItem} from "../../../../../shared/interfaces/menu-item.interface";
import {Icon} from "../../../../../shared/enums/icon.enum";
import {Subject, takeUntil} from "rxjs";
import {BaseComponent} from "../../../../../shared/components/base/base.component";
import {SubmenuType} from "../../../../../shared/enums/submenu-type.enum";

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent extends BaseComponent implements OnInit {

  public toggleSubmenus$: Subject<MenuItem> = new Subject<MenuItem>();
  public menuItemClick$: Subject<void> = new Subject<void>();

  submenusVisible = false;

  @Input()
  isMobile = false;

  @Input()
  menuItem!: MenuItem;

  @Input()
  vertical!: boolean;

  @Input()
  submenuType: SubmenuType = SubmenuType.Dropdown;

  @Output()
  toggleSubmenusVisibility: EventEmitter<MenuItem> = new EventEmitter<MenuItem>();

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.toggleSubmenus$.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe((menuItem) => {
        this.submenusVisible = !this.submenusVisible;
        this.toggleSubmenusVisibility.emit(menuItem);
      });

    this.menuItemClick$.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.menuItem.func?.next(this.menuItem);

        if (this.menuItem.submenus && !this.vertical) {
          this.toggleSubmenus$.next(this.menuItem);
        }
      });
  }

  protected readonly Icon = Icon;
}
