import {Injectable} from '@angular/core';
import {ApiUrlsProvider} from "../../utils/api-url-provider";
import {HttpClient} from "@angular/common/http";
import {Account, AccountEntity} from "../../entities/account.entity";
import {ApiPath} from "../../enums/api-path.enum";
import {map, Observable} from "rxjs";
import {InvoiceAddress, InvoiceAddressEntity} from "../../entities/invoice-address.entity";
import {ShippingAddress, ShippingAddressEntity} from "../../entities/shipping-addres.entity";

@Injectable({
  providedIn: 'root'
})
export class AccountHttpService {

  constructor(
    private apiUrlProvider: ApiUrlsProvider,
    private http: HttpClient
  ) {
  }

  public getAccountData(): Observable<AccountEntity> {
    const url = this.apiUrlProvider.apiLanguageUrl(ApiPath.Account);

    return this.http.get<Account>(url).pipe(
      map((response) => {
        return AccountEntity.fromData(response);
      })
    );
  }

  public updateAccountData(accountData: Partial<Account>): Observable<AccountEntity> {
    const url = this.apiUrlProvider.apiLanguageUrl(ApiPath.AccountUpdate);

    return this.http.put<Account>(url, accountData).pipe(map((response) => AccountEntity.fromData(response)));
  }

  public changePassword(currentPassword: string, password: string, passwordConfirmation: string): Observable<void> {
    const url = this.apiUrlProvider.apiLanguageUrl(ApiPath.ChangePassword);
    const data = {
      current_password: currentPassword,
      password: password,
      password_confirmation: passwordConfirmation
    };

    return this.http.put<void>(url, data).pipe();
  }


  public getInvoiceAddress(): Observable<InvoiceAddressEntity> {
    const url = this.apiUrlProvider.apiLanguageUrl(ApiPath.GetInvoiceAddress);

    return this.http.get<InvoiceAddress>(url).pipe(map((response) => InvoiceAddressEntity.fromData(response)));
  }

  public getShippingAddress(): Observable<ShippingAddressEntity> {
    const url = this.apiUrlProvider.apiLanguageUrl(ApiPath.GetShippingAddress);

    return this.http.get<ShippingAddress>(url).pipe(map((response) => ShippingAddressEntity.fromData(response)));
  }
}
