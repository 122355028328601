import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Icon} from "../../../../../enums/icon.enum";
import {ColumnType} from "../../../column-type.enum";
import {SortEvent} from "../../sort-event";
import {FieldName} from "../../../../../enums/field-name.enum";
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';
import {Subject, takeUntil} from "rxjs";
import {BaseComponent} from "../../../../base/base.component";

@Component({
  selector: 'app-sort-option',
  templateUrl: './sort-option.component.html'
})
export class SortOptionComponent extends BaseComponent implements OnInit {

  public sortingOptionChanged$: Subject<void> = new Subject<void>();

  @Input()
  columnType!: ColumnType;

  @Input()
  fieldName!: FieldName;

  @Input()
  sortDirection!: boolean;

  @Output()
  sortingChanged: EventEmitter<SortEvent> = new EventEmitter<SortEvent>();

  public sortDescription!: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.columnType === ColumnType.Text) {
      if (this.sortDirection) {
        this.sortDescription = _('GENERAL.SORT.DIALOG.ATOZ');
      } else {
        this.sortDescription = _('GENERAL.SORT.DIALOG.ZTOA');
      }
    }

    if (this.columnType === ColumnType.Date) {
      if (this.sortDirection) {
        this.sortDescription = _('GENERAL.SORT.DIALOG.NEWESTTOOLDEST');
      } else {
        this.sortDescription = _('GENERAL.SORT.DIALOG.OLDESTTONEWEST');
      }
    }

    this.sortingOptionChanged$.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.sortingChanged.emit({fieldName: this.fieldName, sortDirection: this.sortDirection});
      });
  }

  protected readonly Icon = Icon;
}
