import {Component, Input} from '@angular/core';
import {LoadingIndicatorType} from "../../enums/loading-indicator.enum";

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss']
})

export class LoadingIndicatorComponent {
  @Input()
  loadingIndicatorType!: LoadingIndicatorType;
  protected readonly LoadingIndicatorType = LoadingIndicatorType;
}
