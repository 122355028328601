export interface NewApiError {
  validation: { [key: string]: string[] };
}

export class NewApiErrorEntity {
  constructor(
    public validation: { [key: string]: string[] }
  ){}

  public static fromData(data: NewApiError): NewApiErrorEntity {
    return new NewApiErrorEntity(
      data.validation
    );
  }
}
