<div [hidden]="!menuItem.isVisible">
  <div
    class="menu-item"
    routerLinkActive="active-parent"
    [ngClass]="vertical ? 'flex-row' : 'flex-column'"
  >
    <app-icon
      [icon]="menuItem.icon!"
      (click)="menuItemClick$.next()"
    ></app-icon>
    <p
      [style.margin]="!vertical ? '0' : ''"
      routerLink="{{ menuItem.route }}"
      (click)="menuItemClick$.next()"
    >
      {{ menuItem.label | translate }}
    </p>
    <app-icon
      class="chevron"
      *ngIf="menuItem.submenus && vertical"
      [icon]="submenusVisible ? Icon.ChevronUp : Icon.ChevronDown"
      (click)="toggleSubmenus$.next(menuItem)"
    ></app-icon>
  </div>
</div>
