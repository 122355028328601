import {Component} from '@angular/core';
import {MenuItemType} from "../../../../../shared/enums/menu-item-type.enum";
import {SubmenuType} from "../../../../../shared/enums/submenu-type.enum";

@Component({
  selector: 'app-bottom-container',
  templateUrl: './bottom-container.component.html',
  styleUrls: ['./bottom-container.component.scss']
})
export class BottomContainerComponent {

  protected readonly MenuItemType = MenuItemType;
  protected readonly SubmenuType = SubmenuType;
}
