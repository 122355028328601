import {FilterValue} from "../enums/filter-values.enum";

export interface OrdersFilter {
  page: number;
  per_page: number;
  sorted_by: string;
  filtered_by_code?: string,
  filtered_by_is_favourite?: boolean;
  filtered_by_created_at_from?: string;
  filtered_by_created_at_to?: string;
}

export class OrdersFilterEntity {
  constructor(
    public page: number,
    public per_page: number,
    public sorted_by: string,
    public filtered_by_code?: string,
    public filtered_by_is_favourite?: boolean,
    public filtered_by_created_at_from?: string,
    public filtered_by_created_at_to?: string
  ) {
  }

  public static fromData(data: OrdersFilter): OrdersFilterEntity {
    return new OrdersFilterEntity(
      data.page,
      data.per_page,
      data.sorted_by,
      data.filtered_by_code,
      data.filtered_by_is_favourite,
      data.filtered_by_created_at_from,
      data.filtered_by_created_at_to
    );
  }

  public static empty(): OrdersFilterEntity {
    return new OrdersFilterEntity(1, 5, FilterValue.CreatedAtDesc, undefined, undefined, undefined, undefined);
  }

  public static custom(elements: number): OrdersFilterEntity {
    return new OrdersFilterEntity(1, elements, FilterValue.CreatedAtDesc, undefined, undefined, undefined, undefined);
  }
}
