import {Component, Input, TemplateRef} from '@angular/core';

@Component({
  selector: '[app-expandable-row]',
  templateUrl: './expandable-row.component.html'
})
export class ExpandableRowComponent {

  @Input()
  rowTemplate!: TemplateRef<any>;

  @Input()
  width!: number;

  @Input()
  element!: any;
}
