import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ApiUrlsProvider} from '../../utils/api-url-provider';
import {ApiPath} from '../../enums/api-path.enum';
import {User} from '../../entities/user.entity';

@Injectable({
  providedIn: 'root'
})
export class AuthHttpService {
  constructor(
    private apiUrlProvider: ApiUrlsProvider,
    private http: HttpClient
  ) {
  }

  public login(email: string, password: string): Observable<User> {
    const url = this.apiUrlProvider.apiUrl(ApiPath.Login);
    const data = {
      client: {
        email: email,
        password: password
      }
    };

    return this.http.post<User>(url, data);
  }

  public signUp(email: string, password: string, passwordRepeat: string, firstName: string, lastName: string, agreement: boolean): Observable<User> {
    const url = this.apiUrlProvider.apiUrl(ApiPath.Register);
    const data = {
      client: {
        email: email,
        password: password,
        password_confirmation: passwordRepeat,
        first_name: firstName,
        last_name: lastName,
        agree_terms_and_cons: agreement
      }
    };

    return this.http.post<User>(url, data);
  }

  public sendResetPasswordLink(email: string): Observable<void> {
    const url = this.apiUrlProvider.apiUrl(ApiPath.SendResetPasswordLink);
    const data = {
      client: {
        email: email
      }
    };

    return this.http.post<void>(url, data);
  }

  public resetPassword(resetPasswordToken: string, password: string, passwordRepeat: string): Observable<any> {
    const url = this.apiUrlProvider.apiUrl(ApiPath.ResetPassword);
    const data = {
      client: {
        reset_password_token: resetPasswordToken,
        password: password,
        password_confirmation: passwordRepeat
      }
    };

    return this.http.put<any>(url, data);
  }

  public logout(): Observable<void> {
    const url = this.apiUrlProvider.apiUrl(ApiPath.Logout);
    return this.http.delete<void>(url);
  }
}
