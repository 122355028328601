import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {OnboardingModule} from '../onboarding/onboarding.module';
import {LayoutModule} from './layout/layout.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from '../shared/shared.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {DictionaryLoaderFactory} from '../shared/utils/translation-dictionary-loader';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {HttpRequestInterceptor} from '../shared/interceptors/http.interceptor';
import {Error404Component} from "../shared/components/error404/error404.component";
import {AngularSvgIconModule} from "angular-svg-icon";
import {ClipboardModule} from "@angular/cdk/clipboard";
import {ToastrModule} from "ngx-toastr";

@NgModule({
  declarations: [AppComponent, Error404Component],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    OnboardingModule,
    LayoutModule,
    SharedModule,
    ToastrModule.forRoot({
      timeOut: 5000
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: DictionaryLoaderFactory,
      }
    }),
    AngularSvgIconModule.forRoot(),
    ClipboardModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
