<div class="flex-row right">
  <app-icon-button
    *ngIf="!isExpandable"
    class="submenu-dialog-button"
    [icon]="Icon.ChevronRight"
    (clicked)="mobileActionType === MobileActionType.Redirection
    ? rowEvents[0].func?.next(element)
    : showSubmenusDialog$.next([element, rowEvents])">
  </app-icon-button>

  <app-icon-button
    *ngIf="isExpandable"
    class="expand-button"
    [icon]="isExpanded ? Icon.ChevronUp : Icon.ChevronDown"
    (clicked)="toggleRowVisibility$.next()">
  </app-icon-button>

  <div class="actions-bar">
    <ng-container *ngFor="let rowEvent of rowEvents">
      <ng-container *ngIf="rowEvent.showCondition ? rowEvent.showCondition(element) : true">

        <ng-container *ngIf="rowEvent.text">
          <app-extended-button
            class="link no-padding"
            [text]="rowEvent.text! | translate"
            (click)="rowEvent.func?.next(element)"
          ></app-extended-button>
        </ng-container>

        <ng-container *ngIf="!rowEvent.text">
          <app-icon-button
            *ngIf="rowEvent.tooltip"
            [icon]="rowEvent.icon"
            [isLoading]="(rowEvent.isLoading | async)! === element.id"
            [stateColor]="!!rowEvent.changeButtonState"
            tooltip="{{ rowEvent.tooltip | translate }}"
            (clicked)="rowEvent.func?.next(element)">
          </app-icon-button>
          <app-icon-button
            *ngIf="!rowEvent.tooltip"
            [icon]="rowEvent.icon"
            [isLoading]="(rowEvent.isLoading | async)! === element.id"
            [stateColor]="!!rowEvent.changeButtonState"
            (clicked)="rowEvent.func?.next(element)">
          </app-icon-button>
        </ng-container>

      </ng-container>
    </ng-container>
  </div>
</div>
