import {
  Component, ElementRef,
  Input,
  Optional,
  Renderer2,
  Self, ViewChild
} from '@angular/core';
import {Subject} from "rxjs";
import {Icon} from "../../../enums/icon.enum";
import {ControlValueAccessor, NgControl} from "@angular/forms";
import {BaseControlComponent} from "../../base-control/base-control.component";

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})

export class SearchInputComponent extends BaseControlComponent implements ControlValueAccessor {

  @Input()
  delay = 1000;

  public changeValue$: Subject<string> = new Subject<string>();

  constructor(
    @Self()
    @Optional()
    override ngControl: NgControl,
    private _renderer: Renderer2
  ) {
    super(ngControl);
    this.ngControl.valueAccessor = this;
  }

  @ViewChild('searchInput') private _inputElement!: ElementRef;

  writeValue(obj: any): void {
    if (this._inputElement)
      this._renderer.setProperty(this._inputElement.nativeElement, 'value', obj);
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  protected readonly Icon = Icon;
}
