import {Component, OnDestroy} from '@angular/core';
import {Observable, Subject, takeUntil} from 'rxjs';

@Component({
  selector: 'app-admin',
  template: '<div></div>'
})
export class BaseComponent implements OnDestroy {
  protected unsubscribe$ = new Subject<void>();

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected bind<T>(source: Observable<T>, target: Subject<T>): void {
    source.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => target.next(data));
  }
}
