<ng-container *ngTemplateOutlet="childControlRef"></ng-container>

<ng-container *ngFor="let validationError of validationErrors!">
  <span
    class="validation-error"
    *ngIf="formControl.hasError(validationError.key) && (formControl.dirty || formControl.touched)"
  >
    {{ validationError.value }}
    <br />
  </span>
</ng-container>

<span
  class="validation-error"
  *ngIf="formControl.hasError('remote')"
  [innerHTML]="formControl.getError('remote')"
></span>
