import { Component } from '@angular/core';
import {Icon} from "../../../shared/enums/icon.enum";

@Component({
  selector: 'app-payment-options',
  templateUrl: './payment-options.component.html',
  styleUrls: ['./payment-options.component.scss']
})
export class PaymentOptionsComponent {

    protected readonly Icon = Icon;
}
