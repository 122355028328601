import {Component} from '@angular/core';
import {Icon} from "../../shared/enums/icon.enum";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  protected readonly Icon = Icon;
}
