import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../shared/services/auth.service';
import {FormService} from '../../services/form.service';
import {Subject, takeUntil} from 'rxjs';
import {ApiErrorEntity} from '../../../shared/entities/api-error.entity';
import {Icon} from "../../../shared/enums/icon.enum";

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})

export class SetPasswordComponent extends BaseComponent implements OnInit {
  public submit$ = new Subject<[Event, FormGroup]>();
  public confirmPasswordReset$: Subject<void> = new Subject<void>();

  public authServiceLoading$: Subject<boolean> = new Subject<boolean>();

  public passwordResetSuccess$: Subject<boolean> = new Subject<boolean>();
  public passwordResetError$: Subject<ApiErrorEntity> = new Subject<ApiErrorEntity>();

  public form: FormGroup;
  public passwordControl = new FormControl('', [Validators.required, Validators.minLength(8)]);

  public errorMessage!: string;
  public resetPasswordConfirmed = false;

  constructor(
    private authService: AuthService,
    private formService: FormService,
    private formBuilder: FormBuilder
  ) {
    super();

    this.form = formBuilder.group({
      passwordControl: this.passwordControl
    });
  }

  ngOnInit(): void {
    this.initReactiveBindings();

    this.submit$.pipe(takeUntil(this.unsubscribe$)).subscribe(([event, form]) => this.submitForm(event, form));

    this.confirmPasswordReset$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.resetPasswordConfirmed = true;
    });

    this.passwordResetError$.pipe(takeUntil(this.unsubscribe$)).subscribe((errorEntity) => {
      this.handleRemoteErrors(errorEntity);
    });
  }

  public submitForm(event: Event, form: FormGroup): void {
    event.preventDefault();

    const resetPasswordToken = 'd7bab5c33a9ac414b11cbdb8db60441f4540b7801aceecaa25681359ed73ae25';

    if (form.valid) {
      this.authService.resetPassword(resetPasswordToken, this.passwordControl.value!, this.passwordControl.value!);
    }
  }

  private initReactiveBindings(): void {
    this.bind(this.authService.passwordResetSuccess$, this.passwordResetSuccess$);
    this.bind(this.authService.passwordResetError$, this.passwordResetError$);
    this.bind(this.authService.authServiceLoading$, this.authServiceLoading$);
  }

  public handleRemoteErrors(error: ApiErrorEntity): string[] {
    if (!error?.errors) {
      return error?.genericErrors;
    }

    for (const key of Object.keys(error.errors)) {
      this.errorMessage = error.errors[key].join('<br>');
    }
    return error.genericErrors;
  }

  protected readonly Icon = Icon;
}
