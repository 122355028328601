import {Component, OnInit} from '@angular/core';
import {Subject, takeUntil} from 'rxjs';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ApiErrorEntity} from '../../../shared/entities/api-error.entity';
import {AuthService} from '../../../shared/services/auth.service';
import {FormService} from '../../services/form.service';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {FormsService} from "../../../shared/services/forms.service";
import {Icon} from "../../../shared/enums/icon.enum";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends BaseComponent implements OnInit {
  public submit$ = new Subject<[Event, FormGroup]>();

  public authServiceLoading$: Subject<boolean> = new Subject<boolean>();

  public sendPasswordResetLinkSuccess$: Subject<boolean> = new Subject<boolean>();
  public sendPasswordResetLinkError$: Subject<ApiErrorEntity> = new Subject<ApiErrorEntity>();

  public form: FormGroup;
  public emailControl = new FormControl('', [Validators.required, Validators.email, Validators.minLength(2), Validators.maxLength(128)]);

  public errorMessage!: string;

  constructor(
    private authService: AuthService,
    private formService: FormService,
    private formBuilder: FormBuilder,
    private formsService: FormsService
  ) {
    super();

    this.form = formBuilder.group({
      emailControl: this.emailControl
    });
  }

  ngOnInit(): void {
    this.initReactiveBindings();

    this.submit$.pipe(takeUntil(this.unsubscribe$)).subscribe(([event, form]) => this.submitForm(event, form));

    this.sendPasswordResetLinkError$.pipe(takeUntil(this.unsubscribe$)).subscribe((errorEntity) => {
      this.formsService.handleRemoteErrors(errorEntity, this.form);
    });
  }

  public submitForm(event: Event, form: FormGroup): void {
    event.preventDefault();

    if (form.valid) {
      this.authService.sendResetPasswordLink(this.emailControl.value!);
    }
  }

  private initReactiveBindings(): void {
    this.bind(this.authService.sendPasswordResetLinkSuccess$, this.sendPasswordResetLinkSuccess$);
    this.bind(this.authService.sendPasswordResetLinkError$, this.sendPasswordResetLinkError$);
    this.bind(this.authService.authServiceLoading$, this.authServiceLoading$);
  }

  protected readonly Icon = Icon;
}
