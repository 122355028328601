import {Component, Input} from '@angular/core';
import {ShippingAddressEntity} from "../../entities/shipping-addres.entity";

@Component({
  selector: 'app-shipping-address',
  templateUrl: './shipping-address.component.html'
})
export class ShippingAddressComponent {

  @Input()
  shippingAddress!: ShippingAddressEntity;
}
