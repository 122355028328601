export enum ColumnType {
  Text,
  Image,
  Date,
  Event,
  Boolean,
  Currency,
  Enum,
  Custom
}
