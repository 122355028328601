<app-base-control
  [childControlRef]="childTemplate"
  [validationErrors]="validationErrors"
>
  <ng-template #childTemplate>
    <p class="xxs semi">{{ label }}</p>

    <div
      id="input-group-div"
      class="flex-row center"
      tabindex="-1"
      [ngClass]="!formControl.valid && (formControl.dirty || formControl.touched) ? 'validation-error' : ''"
    >
      <div *ngIf="icon" class="icon left">
        <app-icon
          [icon]="icon"
          [clickable]="false"
        ></app-icon>
      </div>

      <input
        #inputElement
        [type]="type"
        [placeholder]="placeholder"
        [value]="value"
        [disabled]="disabled"
        (input)="onChange($event)"
      />

      <div class="icon right">
        <ng-container *ngIf="!visiblePassword && (formControl.dirty || formControl.touched)">
          <ng-container *ngIf="formControl.valid">
            <app-icon
              *ngIf="icon"
              [icon]="Icon.Check"
              [color]="'#01B123'"
              [clickable]="false"
            ></app-icon>
          </ng-container>
          <ng-container *ngIf="!formControl.valid">
            <app-icon
              *ngIf="icon"
              [icon]="Icon.Cross"
              [color]="'#fd1016'"
              [clickable]="false"
            ></app-icon>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="visiblePassword">
          <app-icon
            [icon]="Icon.Eye"
            [clickable]="false"
            [color]="'#da557a'"
            (mousedown)="changeInputType('password')"
            (mouseup)="changeInputType('text')"
          ></app-icon>
        </ng-container>
      </div>
    </div>
  </ng-template>
</app-base-control>
