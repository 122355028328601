<div class="container">
  <h2 class="mb-big">{{ 'FTL.FORMS.REGISTER.HEADER' | translate }}</h2>

  <ng-container *ngIf="!registrationStarted">

    <div class="mt-medium mb-big">
      <app-extended-button
        class="filled wide"
        [text]="'FTL.FORMS.REGISTER.BUTTON_REGISTER' | translate"
        (clicked)="startRegistration$.next()"
      ></app-extended-button>
    </div>

    <p class="m semi mb-medium">
      {{ 'FTL.FORMS.REGISTER.DESCRIPTION.HEADER' | translate }}
    </p>
    <ul>
      <li>
        <p class="m">{{ 'FTL.FORMS.REGISTER.DESCRIPTION.ELEMENT1' | translate }}</p>
      </li>
      <li>
        <p class="m">{{ 'FTL.FORMS.REGISTER.DESCRIPTION.ELEMENT2' | translate }}</p>
      </li>
      <li>
        <p class="m">{{ 'FTL.FORMS.REGISTER.DESCRIPTION.ELEMENT3' | translate }}</p>
      </li>
    </ul>
  </ng-container>

  <ng-container *ngIf="registrationStarted">

    <form
      [formGroup]="form"
      (ngSubmit)="submit$.next([$event, form])"
    >
      <app-extended-input
        formControlName="nameControl"
        [icon]="Icon.User"
        [label]="'FTL.FORMS.REGISTER.LABEL_FIRST_NAME' | translate"
        [placeholder]="'FTL.FORMS.REGISTER.PLACEHOLDER_FIRST_NAME' | translate"
        [validationErrors]="[
          {
            key: 'required',
            value: 'FTL.FORMS.VALIDATION.FIRST_NAME.REQUIRED' | translate
          },
          {
            key: 'minlength',
            value: 'FTL.FORMS.VALIDATION.FIRST_NAME.MINLENGTH' | translate
          },
          {
            key: 'maxlength',
            value: 'FTL.FORMS.VALIDATION.FIRST_NAME.MAXLENGTH' | translate
          }
        ]"
        type="text"
      ></app-extended-input>

      <div class="mt-medium">
        <app-extended-input
          formControlName="surnameControl"
          [icon]="Icon.User"
          [label]="'FTL.FORMS.REGISTER.LABEL_LAST_NAME' | translate"
          [placeholder]="'FTL.FORMS.REGISTER.PLACEHOLDER_LAST_NAME' | translate"
          [validationErrors]="[
            {
              key: 'required',
              value: 'FTL.FORMS.VALIDATION.LAST_NAME.REQUIRED' | translate
            },
            {
              key: 'minlength',
              value: 'FTL.FORMS.VALIDATION.LAST_NAME.MINLENGTH' | translate
            },
            {
              key: 'maxlength',
              value: 'FTL.FORMS.VALIDATION.LAST_NAME.MAXLENGTH' | translate
            }
          ]"
          type="text"
        ></app-extended-input>
      </div>

      <div class="mt-medium">
        <app-extended-input
          formControlName="emailControl"
          [icon]="Icon.Envelop"
          [label]="'FTL.FORMS.REGISTER.LABEL_EMAIL' | translate"
          [placeholder]="'FTL.FORMS.REGISTER.PLACEHOLDER_EMAIL' | translate"
          [validationErrors]="[
            {
              key: 'required',
              value: 'FTL.FORMS.VALIDATION.EMAIL.REQUIRED' | translate
            },
            {
              key: 'email',
              value: 'FTL.FORMS.VALIDATION.EMAIL.EMAIL' | translate
            },
            {
              key: 'minlength',
              value: 'FTL.FORMS.VALIDATION.EMAIL.MINLENGTH' | translate
            },
            {
              key: 'maxlength',
              value: 'FTL.FORMS.VALIDATION.EMAIL.MAXLENGTH' | translate
            }
          ]"
          type="email"
        ></app-extended-input>
      </div>

      <div class="mt-medium">
        <app-extended-input
          formControlName="passwordControl"
          [icon]="Icon.Key"
          [label]="'FTL.FORMS.REGISTER.LABEL_PASSWORD' | translate"
          [placeholder]="'FTL.FORMS.REGISTER.PLACEHOLDER_PASSWORD' | translate"
          [visiblePassword]="true"
          [validationErrors]="[
            {
              key: 'required',
              value: 'FTL.FORMS.VALIDATION.PASSWORD.REQUIRED' | translate
            },
            {
              key: 'minlength',
              value: 'FTL.FORMS.VALIDATION.PASSWORD.MINLENGTH' | translate
            }
          ]"
          type="password"
        ></app-extended-input>
        <p class="xxs grey mt-small">{{'FTL.FORMS.SET_PASSWORD.NEW_PASSWORD_HINT' | translate}}</p>
      </div>

      <div class="mt-medium">
        <app-extended-input
          formControlName="passwordRepeatControl"
          [icon]="Icon.Key"
          [label]="'FTL.FORMS.REGISTER.LABEL_CONFIRM_PASSWORD' | translate"
          [placeholder]="'FTL.FORMS.REGISTER.PLACEHOLDER_CONFIRM_PASSWORD' | translate"
          [visiblePassword]="true"
          [validationErrors]="[
            {
              key: 'required',
              value: 'FTL.FORMS.VALIDATION.CONFIRM_PASSWORD.REQUIRED' | translate
            },
            {
              key: 'minlength',
              value: 'FTL.FORMS.VALIDATION.CONFIRM_PASSWORD.MINLENGTH' | translate
            },
            {
              key: 'matching',
              value: 'FTL.FORMS.VALIDATION.CONFIRM_PASSWORD.MATCHING' | translate
            }
          ]"
          type="password"
        ></app-extended-input>
      </div>

      <div class="mt-big">
        <app-extended-checkbox
          formControlName="termsControl"
          class="hoverable"
          [label]="'FTL.FORMS.REGISTER.LABEL_TERMS' | translate"
        ></app-extended-checkbox>
      </div>

      <div class="mt-big mb-medium">
        <app-extended-button
          class="filled wide mt-big"
          [text]="'FTL.FORMS.REGISTER.BUTTON_REGISTER' | translate"
          [disabled]="form.invalid || (authServiceLoading$ | async)!"
        ></app-extended-button>
      </div>
    </form>

    <p
      class="text-error"
      *ngIf="errorMessage"
    >
      {{ errorMessage }}
    </p>
  </ng-container>
</div>
