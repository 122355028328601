import {OrderStatus} from "../enums/order-status.enum";

export interface Order {
  id: number,
  code: string,
  is_favourite: boolean,
  created_at: Date,
  is_invoice: boolean,
  total_value: number,
  currency: string,
  status: OrderStatus
}

export class OrderEntity {
  constructor(
    public id: number,
    public code: string,
    public isFavourite: boolean,
    public createdAt: Date,
    public isInvoice: boolean,
    public totalValue: number,
    public currency: string,
    public status: OrderStatus
  ) {
  }

  public static fromData(data: Order): OrderEntity {
    return new OrderEntity(
      data.id,
      data.code,
      data.is_favourite,
      data.created_at,
      data.is_invoice,
      data.total_value,
      data.currency,
      data.status,
    );
  }
}


