export interface Page<T> {
  page: number;
  per_page: number;
  count: number;
  pages: number;
  elements: T[];
}

export class PageEntity<T> {
  public isEmpty: boolean;

  constructor(
    public page: number,
    public perPage: number,
    public count: number,
    public pages: number,
    public elements: T[],
  ) {
    this.isEmpty = elements.length === 0;
  }

  public static fromData(data: Page<any>): PageEntity<any> {
    return new PageEntity(
      data.page,
      data.per_page,
      data.count,
      data.pages,
      data.elements,
    );
  }
}
