import {Component, ElementRef, Input, Optional, Renderer2, Self, ViewChild} from '@angular/core';
import {ControlValueAccessor, NgControl} from '@angular/forms';
import {BaseControlComponent} from '../base-control/base-control.component';
import {Icon} from '../../enums/icon.enum';

@Component({
  selector: 'app-extended-input',
  templateUrl: './extended-input.component.html',
  styleUrls: ['./extended-input.component.scss']
})
export class ExtendedInputComponent extends BaseControlComponent implements ControlValueAccessor {
  @Input()
  type: 'text' | 'email' | 'password' = 'text';

  @Input()
  icon: Icon | null = null;

  @Input()
  visiblePassword = false;

  constructor(
    @Self()
    @Optional()
    override ngControl: NgControl,
    private _renderer: Renderer2
  ) {
    super(ngControl);
    this.ngControl.valueAccessor = this;
  }

  @ViewChild('inputElement') private _inputElement!: ElementRef;

  writeValue(obj: any): void {
    if (this._inputElement)
      this._renderer.setProperty(this._inputElement.nativeElement, 'value', obj);
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public changeInputType(type: string): void {
    if (type === 'text') {
      this.type = 'password';
    } else {
      this.type = 'text';
    }
  }

  protected readonly Icon = Icon;
}
