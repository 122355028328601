import {HttpHeaders, HttpParams} from "@angular/common/http";

export interface RequestOptionsArgs {
  body?: any;
  headers?: HttpHeaders;
  observe: 'response';
  params?:
    | HttpParams
    | {
    [param: string]: string | string[];
  };
  reportProgress?: boolean;
  responseType: 'arraybuffer' | 'blob' | 'json' | 'text';
  withCredentials?: boolean;
}

export class RequestOptions implements RequestOptionsArgs {
  body: any;
  headers!: HttpHeaders;
  observe = 'response' as const;
  params!: HttpParams | { [p: string]: string | string[] };
  reportProgress!: boolean;
  responseType: 'arraybuffer' | 'blob' | 'json' | 'text' = 'json';
  withCredentials!: boolean;
}
