import {Component, Input} from '@angular/core';
import {ApiUrlsProvider} from "../../utils/api-url-provider";
import {Icon} from "../../enums/icon.enum";

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent {

  @Input()
  icon!: Icon;

  @Input()
  color!: string;

  @Input()
  clickable = true;

  @Input()
  isLoading = false;

  @Input()
  isPNG = false;

  constructor(
    public apiUrlsProvider: ApiUrlsProvider
  ) {
  }
}
