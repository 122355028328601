import {Component, OnInit} from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {BaseComponent} from "../../../shared/components/base/base.component";
import {Icon} from "../../../shared/enums/icon.enum";
import {AuthService} from "../../../shared/services/auth.service";
import {ApiUrlsProvider} from "../../../shared/utils/api-url-provider";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit {

  public searchValueChanged$: Subject<Event> = new Subject<Event>();
  public logout$: Subject<void> = new Subject<void>();

  public form: FormGroup;
  public searchControl = new FormControl('');

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    public apiUrlsProvider: ApiUrlsProvider,
  ) {
    super();

    this.form = formBuilder.group({
      searchControl: this.searchControl
    });
  }

  ngOnInit(): void {
    this.searchValueChanged$.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe((event) => {
        const searchValue = (event.target as HTMLInputElement).value;
        window.location.href = this.apiUrlsProvider.searchDesignUrl(searchValue);
      });

    this.logout$.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.authService.logout(true);
      });
  }

  protected readonly Icon = Icon;
}
