import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TopMenuService {
  public topMenuVisibility$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private isMenuVisible = false;

  constructor() {
  }

  public toggle(): void {
    this.isMenuVisible = !this.isMenuVisible;
    this.topMenuVisibility$.next(this.isMenuVisible);
  }

  public close(): void {
    this.isMenuVisible = false;
    this.topMenuVisibility$.next(this.isMenuVisible);
  }
}
