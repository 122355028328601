import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';
import {ColumnDefinition} from "./column-definition";
import {PageEntity} from "../../entities/page.entity";
import {ColumnType} from "./column-type.enum";
import {PageEvent} from "./paginator/page-event";
import {RowEvent} from "./row-event";
import {SortEvent} from "./sorting/sort-event";
import {Icon} from "../../enums/icon.enum";
import {pipe, Subject, takeUntil} from "rxjs";
import {BaseComponent} from "../base/base.component";
import {DateTimeFormat} from "../../enums/date-time-format.enum";
import {DialogService} from "@ngneat/dialog";
import {ImageComponent} from "./image/image.component";
import {BottomMenuService} from "../../../app/layout/menu/services/bottom-menu.service";
import {ExtendedTableService} from "./services/extended-table.service";
import {TableKey} from "../../enums/table-key.enum";
import {LocalStorageService} from "../../services/local-storage.service";
import {LoadingIndicatorType} from "../../enums/loading-indicator.enum";

@Component({
  selector: 'app-extended-table',
  templateUrl: './extended-table.component.html',
  styleUrls: ['./extended-table.component.scss'],
  providers: [ExtendedTableService]
})
export class ExtendedTableComponent extends BaseComponent implements OnInit {

  @ContentChild("customTemplate") customTemplateRef!: TemplateRef<any>;
  @ContentChild("customRowTemplate") customRowTemplateRef!: TemplateRef<any>;

  @Input()
  columns!: ColumnDefinition[];

  @Input()
  pageEntity!: PageEntity<any>;

  @Input()
  rowEvents!: RowEvent[];

  @Input() set sortEvent(sortEvent: SortEvent) {
    this.extendedTableService.sortingChanged$.next(sortEvent);
  }

  @Input()
  loading!: boolean;

  @Input()
  paginator = true;

  @Input()
  sortable = true;

  @Input()
  expandable = false;

  @Input()
  noContentMessage!: string;

  @Input()
  editablePageSize = false;

  @Input()
  tableKey!: TableKey;

  @Output()
  pageChanged: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();

  @Output()
  sortDirectionChanged: EventEmitter<SortEvent> = new EventEmitter<SortEvent>();

  public changePagination$: Subject<PageEvent> = new Subject<PageEvent>();
  public magnifyImage: Subject<string> = new Subject<string>();

  public expandedRow!: number | null;

  constructor(
    private extendedTableService: ExtendedTableService,
    private dialogService: DialogService,
    private bottomMenuService: BottomMenuService,
    private localStorageService: LocalStorageService
  ) {
    super();
  }

  ngOnInit(): void {
    this.changePagination$.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe((pageEvent) => {
          this.pageChanged.next(pageEvent);

          if(this.editablePageSize)
            this.localStorageService.save(this.tableKey, pageEvent.currentPageSize.toString());
        }
      );

    this.extendedTableService.sortingChanged$.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe((sortEvent) => {
        this.sortDirectionChanged.next(sortEvent);
      });

    this.extendedTableService.rowExpanded$.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe((rowNumber) => {
        this.expandedRow = rowNumber;
      });

    this.magnifyImage.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe((thumbnailUrl) => {
        this.bottomMenuService.hide();
        const dialogRef = this.dialogService.open(ImageComponent, {
          id: 'image-dialog',
          width: 'auto',
          data: {
            imageUrl: thumbnailUrl
          }
        });

        dialogRef.afterClosed$.subscribe(() => {
          this.bottomMenuService.show();
        });
      });
  }

  protected readonly ColumnType = ColumnType;
  protected readonly Icon = Icon;
  protected readonly pipe = pipe;
  protected readonly DateTimeFormat = DateTimeFormat;
  protected readonly length = length;
  protected readonly LoadingIndicatorType = LoadingIndicatorType;
}
