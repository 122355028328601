<app-base-control [childControlRef]="childTemplate">
  <ng-template #childTemplate>
    <p
      class="xxs mb-small"
      *ngIf="label !== ''"
    >
      {{ label }}
    </p>
    <input
      #datepickerElement
      [class.danger-text]="formControl.valid !== true"
      type="date"
      [placeholder]="placeholder"
      [value]="value"
      [disabled]="disabled"
      (change)="onChange($event)"
    />
  </ng-template>
</app-base-control>
