import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {Subject, takeUntil} from 'rxjs';
import {AuthService} from '../../../shared/services/auth.service';
import {ApiErrorEntity} from '../../../shared/entities/api-error.entity';
import {PasswordMatchValidator} from '../../../shared/validators/password-match.validator';
import {ApiUrlsProvider} from "../../../shared/utils/api-url-provider";
import {FormsService} from "../../../shared/services/forms.service";
import {Router} from "@angular/router";
import {RoutePath} from "../../../shared/enums/route-path.enum";
import {Icon} from "../../../shared/enums/icon.enum";

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent extends BaseComponent implements OnInit {

  public submit$ = new Subject<[Event, FormGroup]>();
  public startRegistration$: Subject<void> = new Subject<void>();
  public quickShopping$: Subject<void> = new Subject<void>();

  public registerSuccess$: Subject<void> = new Subject<void>();
  public registerError$: Subject<ApiErrorEntity> = new Subject<ApiErrorEntity>();

  public authServiceLoading$: Subject<boolean> = new Subject<boolean>();

  public form: FormGroup;
  public nameControl = new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(64)]);
  public surnameControl = new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(64)]);
  public emailControl = new FormControl('', [Validators.required, Validators.email, Validators.minLength(2), Validators.maxLength(128)]);
  public passwordControl = new FormControl('', [Validators.required, Validators.minLength(8)]);
  public passwordRepeatControl = new FormControl('', [Validators.required, Validators.minLength(8)]);
  public termsControl = new FormControl(false, [Validators.requiredTrue]);

  public errorMessage!: string;
  public registrationStarted = false;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private apiUrlsProvider: ApiUrlsProvider,
    private router: Router,
    private formsService: FormsService
  ) {
    super();

    this.form = formBuilder.group(
      {
        nameControl: this.nameControl,
        surnameControl: this.surnameControl,
        emailControl: this.emailControl,
        passwordControl: this.passwordControl,
        passwordRepeatControl: this.passwordRepeatControl,
        termsControl: this.termsControl
      },
      {
        validators: [PasswordMatchValidator('passwordControl', 'passwordRepeatControl')]
      }
    );
  }

  ngOnInit(): void {
    this.initReactiveBindings();

    this.submit$.pipe(takeUntil(this.unsubscribe$)).subscribe(([event, form]) => this.submitForm(event, form));

    this.startRegistration$.pipe().subscribe(() => {
      this.registrationStarted = true;
    });

    this.registerSuccess$.pipe().subscribe(() => {
      this.router.navigate([RoutePath.Home]);
    });

    this.registerError$.pipe(takeUntil(this.unsubscribe$)).subscribe((errorEntity) => {
      this.formsService.handleRemoteErrors(errorEntity, this.form);
    });

    this.quickShopping$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      window.location.href = this.apiUrlsProvider.backUrl$.value;
    });
  }

  private submitForm(event: Event, form: FormGroup): void {
    event.preventDefault();

    if (form.valid) {
      this.authService.signUp(
        this.emailControl.value!,
        this.passwordControl.value!,
        this.passwordRepeatControl.value!,
        this.nameControl.value!,
        this.surnameControl.value!,
        this.termsControl.value!
      );
    }
  }

  private initReactiveBindings(): void {
    this.bind(this.authService.registerSuccess$, this.registerSuccess$);
    this.bind(this.authService.registerError$, this.registerError$);
    this.bind(this.authService.authServiceLoading$, this.authServiceLoading$);
  }

  protected readonly Icon = Icon;
}
