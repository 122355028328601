<div
  [hidden]="!submenuItem.isVisible"
  (click)="submenuItem.func?.next(submenuItem)"
>
  <div
    class="submenu-item"
    routerLinkActive="active-child"
  >
    <p
      class="s"
      routerLink="{{ redirectRoute }}"
    >
      {{ submenuItem.label | translate }}
    </p>
  </div>
</div>
