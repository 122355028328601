<div>
  <app-icon
    *ngIf="sortDirection"
    [color]="justSorted ? '#da557a' : 'black'"
    [icon]="Icon.ChevronUp"
    (click)="onSortDirectionChanged$.next(false)"
  ></app-icon>
  <app-icon
    *ngIf="!sortDirection"
    [color]="justSorted ? '#da557a' : 'black'"
    [icon]="Icon.ChevronDown"
    (click)="onSortDirectionChanged$.next(true)"
  ></app-icon>
</div>
