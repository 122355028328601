<div
  class="flex-column m-medium"
  *ngIf="shippingAddress"
>
  <p class="xs semi mb-small">{{ 'PANEL.ADDRESS.SHIPPING' | translate }}</p>
  <p class="s">{{ shippingAddress.fullName }}</p>
  <p class="s">
    {{ 'PANEL.ADDRESS.STREET' | translate }} {{ shippingAddress.street }}
    {{ shippingAddress.houseNo }}
  </p>
  <p class="s">{{ shippingAddress.postCode }}, {{ shippingAddress.city }}</p>
  <p class="s">{{ shippingAddress.country }}</p>
  <p class="s">{{ 'PANEL.ADDRESS.EMAIL' | translate }} {{ shippingAddress.email }}</p>
  <br />
  <p class="s">{{ 'PANEL.ADDRESS.PHONE_NUMBER' | translate }} {{ shippingAddress.phone }}</p>
</div>
