import {Injectable} from '@angular/core';
import {AuthHttpService} from './http/auth-http.service';
import {catchError, EMPTY, Subject, Subscription, take} from 'rxjs';
import {ApiErrorEntity} from '../entities/api-error.entity';
import {UserEntity} from '../entities/user.entity';
import {indicate} from '../../../../operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public loginSuccess$: Subject<void> = new Subject<void>();
  public loginError$: Subject<ApiErrorEntity> = new Subject<ApiErrorEntity>();

  public logoutSuccess$: Subject<void> = new Subject<void>();
  public logoutError$: Subject<ApiErrorEntity> = new Subject<ApiErrorEntity>();

  public registerSuccess$: Subject<void> = new Subject<void>();
  public registerError$: Subject<ApiErrorEntity> = new Subject<ApiErrorEntity>();

  public sendPasswordResetLinkSuccess$: Subject<boolean> = new Subject<boolean>();
  public sendPasswordResetLinkError$: Subject<ApiErrorEntity> = new Subject<ApiErrorEntity>();

  public passwordResetSuccess$: Subject<boolean> = new Subject<boolean>();
  public passwordResetError$: Subject<ApiErrorEntity> = new Subject<ApiErrorEntity>();

  public authServiceLoading$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private authHttpService: AuthHttpService
  ) {}

  public login(email: string, password: string): Subscription {
    return this.authHttpService
      .login(email, password)
      .pipe(
        indicate(this.authServiceLoading$),
        catchError((errorResponse) => {
          this.loginError$.next(ApiErrorEntity.fromData(errorResponse.error));
          return EMPTY;
        }),
        take(1)
      )
      .subscribe((successResponse) => {
        this.loginSuccess$.next();
        localStorage.setItem('isLogged', 'true');
      });
  }

  public signUp(email: string, password: string, passwordRepeat: string, firstName: string, lastName: string, agreement: boolean): Subscription {
    return this.authHttpService
      .signUp(email, password, passwordRepeat, firstName, lastName, agreement)
      .pipe(
        indicate(this.authServiceLoading$),
        catchError((errorResponse) => {
          this.registerError$.next(ApiErrorEntity.fromData(errorResponse.error));
          return EMPTY;
        }),
        take(1)
      )
      .subscribe((successResponse) => {
        UserEntity.fromData(successResponse);
        this.registerSuccess$.next();
        this.loginSuccess$.next();
        localStorage.setItem('isLogged', 'true');
      });
  }

  public sendResetPasswordLink(email: string): any {
    return this.authHttpService
      .sendResetPasswordLink(email)
      .pipe(
        indicate(this.authServiceLoading$),
        catchError((errorResponse) => {
          this.sendPasswordResetLinkError$.next(ApiErrorEntity.fromData(errorResponse.error));
          return EMPTY;
        }),
        take(1)
      )
      .subscribe(() => {
        this.sendPasswordResetLinkSuccess$.next(true);
      });
  }

  public resetPassword(resetPasswordToken: string, password: string, passwordRepeat: string): any {
    return this.authHttpService
      .resetPassword(resetPasswordToken, password, passwordRepeat)
      .pipe(
        indicate(this.authServiceLoading$),
        catchError((errorResponse) => {
          this.passwordResetError$.next(ApiErrorEntity.fromData(errorResponse.error));
          return EMPTY;
        }),
        take(1)
      )
      .subscribe(() => {
        this.passwordResetSuccess$.next(true);
      });
  }

  public logout(withRedirect = true): void {
    this.authHttpService
      .logout()
      .pipe(
        catchError((errorResponse) => {
          this.logoutError$.next(errorResponse);
          return EMPTY;
        }),
        take(1)
      )
      .subscribe(() => {
        localStorage.removeItem('isLogged');
        if (withRedirect)
          this.logoutSuccess$.next();
      });
  }
}
