import {ShippingAddress, ShippingAddressEntity} from "./shipping-addres.entity";
import {OrderItem} from "./order-item.entity";
import {OrderItemsPageEntity} from "./order-items-page.entity";
import {InvoiceAddress, InvoiceAddressEntity} from "./invoice-address.entity";
import {OrderStatus} from "../enums/order-status.enum";

export interface OrderDetails {
  id: number,
  code: string,
  is_favourite: boolean,
  created_at: Date,
  is_invoice: boolean,
  total_value: string,
  currency: string,
  status: OrderStatus,
  shipping_numbers: number[],
  courier_company_url: string,
  shipping_address: ShippingAddress,
  invoice_address: InvoiceAddress | null,
  order_items: OrderItem[]
}

export class OrderDetailsEntity {
  constructor(
    public id: number,
    public code: string,
    public isFavourite: boolean,
    public createdAt: Date,
    public isInvoice: boolean,
    public totalValue: string,
    public currency: string,
    public status: OrderStatus,
    public shippingNumbers: number[],
    public courierCompanyUrl: string,
    public shippingAddress: ShippingAddressEntity,
    public invoiceAddress: InvoiceAddressEntity | null,
    public orderItems: OrderItemsPageEntity,
  ) {
  }

  public static fromData(data: OrderDetails): OrderDetailsEntity {
    return new OrderDetailsEntity(
      data.id,
      data.code,
      data.is_favourite,
      data.created_at,
      data.is_invoice,
      data.total_value,
      data.currency,
      data.status,
      data.shipping_numbers,
      data.courier_company_url,
      ShippingAddressEntity.fromData(data.shipping_address),
      data.invoice_address ? InvoiceAddressEntity.fromData(data.invoice_address) : null,
      OrderItemsPageEntity.fromData({page: 0, per_page: 0, pages: 0, count: 0, elements: data.order_items})
    );
  }
}
