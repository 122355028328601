export interface CartSummary {
  total_quantity: number,
  currency: string,
  predicted_send_date: Date,
  total_price: number
}

export class CartSummaryEntity {
  constructor(
    public totalQuantity: number,
    public currency: string,
    public predictedSendDate: Date,
    public totalPrice: number
  ) {
  }

  public static fromData(data: CartSummary): CartSummaryEntity {
    return new CartSummaryEntity(
      data.total_quantity,
      data.currency,
      data.predicted_send_date,
      data.total_price,
    );
  }
}


