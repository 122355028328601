import {Observable, of as observableOf} from 'rxjs';
import {TranslateLoader} from '@ngx-translate/core';

import {registerLocaleData} from '@angular/common';
import localePl from '@angular/common/locales/pl';
import localePlExtra from '@angular/common/locales/extra/pl';
import {Language} from "../enums/language.enum";

registerLocaleData(localePl, 'pl-PL', localePlExtra);

export function DictionaryLoaderFactory(): TranslateDictionaryLoader {
  const dictionary: {[key: string]: string} = {};
  dictionary[Language.Polish] = require('../../../i18n/pl.json');
  dictionary[Language.English] = require('../../../i18n/en.json');
  dictionary[Language.German] = require('../../../i18n/de.json');
  dictionary[Language.French] = require('../../../i18n/fr.json');
  return new TranslateDictionaryLoader(dictionary);
}

export class TranslateDictionaryLoader implements TranslateLoader {
  constructor(private translationDictionaries: {[key: string]: string}) {}

  getTranslation(lang: string): Observable<string> {
    const dataStrings = this.translationDictionaries[lang];
    return observableOf(dataStrings);
  }
}
