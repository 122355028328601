import {Component, OnInit} from '@angular/core';
import {AccountService} from "../../account/services/account.service";
import {BehaviorSubject, Subject, takeUntil} from "rxjs";
import {BaseComponent} from "../../shared/components/base/base.component";
import {Icon} from "../../shared/enums/icon.enum";
import {TopMenuService} from "./menu/services/top-menu.service";
import {BottomMenuService} from "./menu/services/bottom-menu.service";
import {LeftMenuService} from "./menu/services/left-menu.service";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent extends BaseComponent implements OnInit {

  public toggleTopMenu$: Subject<void> = new Subject<void>();
  public topMenuVisibility$: Subject<boolean> = new Subject<boolean>();
  public leftMenuVisibility$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public bottomMenuVisibility$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private topMenuService: TopMenuService,
    private leftMenuService: LeftMenuService,
    private bottomMenuService: BottomMenuService,
    private accountService: AccountService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initReactiveBinding();
    this.accountService.loadAccountData();

    this.toggleTopMenu$.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.topMenuService.toggle();
      });
  }

  private initReactiveBinding(): void {
    this.bind(this.topMenuService.topMenuVisibility$, this.topMenuVisibility$);
    this.bind(this.leftMenuService.leftMenuVisibility$, this.leftMenuVisibility$);
    this.bind(this.bottomMenuService.bottomMenuVisibility$, this.bottomMenuVisibility$);
  }

  protected readonly Icon = Icon;
}
