export enum Icon {
  History = 'History',
  Trash = 'Trash',
  External = 'External',
  Download = 'Download',
  Upload = 'Upload',
  MagnifyingGlass = 'MagnifyingGlass',
  ChevronUp = 'ChevronUp',
  ChevronDown = 'ChevronDown',
  ChevronLeft = 'ChevronLeft',
  ChevronRight = 'ChevronRight',
  BookmarkFilled = 'BookmarkFilled',
  BookmarkEmpty = 'BookmarkEmpty',
  Button = 'btn empty primary-panel',
  ArrowLeft = 'ArrowLeft',
  Check = 'Check',
  CheckCircle = 'CheckCircle',
  Cross = 'Cross',
  Eye = 'Eye',
  Envelop = 'Envelop',
  Key = 'Key',
  User = 'User',
  UserCircle = 'UserCircle',
  Tag = 'Tag',
  Collection = 'Collection',
  House = 'Home',
  Phone = 'Phone',
  Cart = 'Cart',
  Copy = 'Copy',
  Menu = 'Menu',
  DotsHorizontal = 'DotsHorizontal',
  Filter = 'Filter',

  Maestro = 'Maestro',
  Mastercard = 'Mastercard',
  Paypal = 'Paypal',
  SSL = 'SSL',
  Visa = 'Visa',

  TNT = 'TNT',
  PocztaPolska = 'PocztaPolska',
  RoyalMail = 'RoyalMail'
}
