import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {BaseComponent} from './components/base/base.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ApiUrlsProvider} from './utils/api-url-provider';
import {HttpClientModule} from '@angular/common/http';
import {ExtendedInputComponent} from './components/extended-input/extended-input.component';
import {TranslateModule} from '@ngx-translate/core';
import {ExtendedTableComponent} from './components/extended-table/extended-table.component';
import {LoadingIndicatorComponent} from './components/loading-indicator/loading-indicator.component';
import {SortButtonComponent} from './components/extended-table/sorting/sort-button/sort-button.component';
import {EnumTranslatePipe} from "./pipes/enum-translate.pipe";
import {ExtendedButtonComponent} from './components/extended-button/extended-button.component';
import {SearchInputComponent} from './components/search-input-button/search-input/search-input.component';
import {BaseControlComponent} from './components/base-control/base-control.component';
import {ExtendedDatepickerComponent} from './components/extended-datepicker/extended-datepicker.component';
import {ExtendedCheckboxComponent} from './components/extended-checkbox/extended-checkbox.component';
import {ShippingAddressComponent} from './components/shipping-address/shipping-address.component';
import {InvoiceAddressComponent} from './components/invoice-address/invoice-address.component';
import {ChipComponent} from './components/chip/chip.component';
import {TooltipDirective} from './directives/tooltip.directive';
import {OrderStatusDisplayComponent} from './components/order-status-display/order-status-display.component';
import {NoContentComponent} from './components/extended-table/no-content/no-content.component';
import {PaginatorComponent} from './components/extended-table/paginator/paginator.component';
import {IconComponent} from './components/icon/icon.component';
import {AngularSvgIconModule} from "angular-svg-icon";
import {ImageComponent} from './components/extended-table/image/image.component';
import {BoldPipe} from './pipes/bold.pipe';
import {ButtonDialogComponent} from './components/button-dialog/button-dialog.component';
import {SortDialogComponent} from './components/extended-table/sorting/sort-dialog/sort-dialog.component';
import {SortOptionComponent} from './components/extended-table/sorting/sort-dialog/sort-option/sort-option.component';
import {OrderSummaryComponent} from './components/order-summary/order-summary.component';
import {ClipboardModule} from "@angular/cdk/clipboard";
import {ExpandableRowComponent} from './components/extended-table/expandable-row/expandable-row.component';
import {LogoComponent} from './components/logo/logo.component';
import {SearchInputButtonComponent} from './components/search-input-button/search-input-button.component';
import {ActionsCellComponent} from './components/extended-table/actions-cell/actions-cell.component';
import {TrimPipe} from './pipes/trim.pipe';
import {NoAuthGuard} from "./guards/no-auth.guard";
import {LocalStorageService} from "./services/local-storage.service";
import {IconButtonComponent} from "./components/icon-button/icon-button.component";

@NgModule({
  declarations: [
    BaseComponent,
    ExtendedInputComponent,
    ExtendedTableComponent,
    LoadingIndicatorComponent,
    SortButtonComponent,
    EnumTranslatePipe,
    ExtendedButtonComponent,
    SearchInputComponent,
    BaseControlComponent,
    ExtendedDatepickerComponent,
    ExtendedCheckboxComponent,
    ShippingAddressComponent,
    InvoiceAddressComponent,
    ChipComponent,
    TooltipDirective,
    OrderStatusDisplayComponent,
    NoContentComponent,
    PaginatorComponent,
    IconComponent,
    ImageComponent,
    BoldPipe,
    ButtonDialogComponent,
    SortDialogComponent,
    SortOptionComponent,
    OrderSummaryComponent,
    ExpandableRowComponent,
    LogoComponent,
    SearchInputButtonComponent,
    ActionsCellComponent,
    TrimPipe,
    IconButtonComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    AngularSvgIconModule,
    ClipboardModule
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    ExtendedInputComponent,
    TranslateModule,
    ExtendedTableComponent,
    EnumTranslatePipe,
    ExtendedButtonComponent,
    SearchInputComponent,
    ExtendedDatepickerComponent,
    ExtendedCheckboxComponent,
    ShippingAddressComponent,
    InvoiceAddressComponent,
    ChipComponent,
    OrderStatusDisplayComponent,
    IconComponent,
    BoldPipe,
    OrderSummaryComponent,
    LogoComponent,
    SearchInputButtonComponent
  ],
  providers: [ApiUrlsProvider, NoAuthGuard, DatePipe, LocalStorageService,
    {
      provide: 'Window', useValue: window
    }]
})
export class SharedModule {
}
