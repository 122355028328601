import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Subject, takeUntil} from "rxjs";
import {OrdersTableService} from "../../../orders/orders-content/orders/orders-table.service";
import {BaseComponent} from "../base/base.component";
import {FilterKey} from "../../enums/filter-key.enum";

@Component({
  selector: 'app-search-input-button',
  templateUrl: './search-input-button.component.html',
  styleUrls: ['./search-input-button.component.scss']
})
export class SearchInputButtonComponent extends BaseComponent implements OnInit {

  @Output()
  searchEvent: EventEmitter<string> = new EventEmitter<string>();

  public search$: Subject<void> = new Subject<void>();
  public ordersTableFiltersReset$: Subject<void> = new Subject<void>();

  public form: FormGroup;
  public searchControl = new FormControl('');

  constructor(
    private ordersTableService: OrdersTableService,
    private formBuilder: FormBuilder,
  ) {
    super();

    this.form = formBuilder.group({
      searchControl: this.searchControl
    });
  }

  ngOnInit(): void {
    this.initReactiveBinding();

    this.search$.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.ordersTableService.setFilterWithPageReset(FilterKey.FilteredByCode, this.searchControl.value!);
      });

    this.ordersTableFiltersReset$.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.searchControl.setValue('', {emitEvent: false});
      });
  }

  private initReactiveBinding(): void {
    this.bind(this.ordersTableService.ordersTableFiltersReset$, this.ordersTableFiltersReset$);
  }
}
