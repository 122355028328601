import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {Router} from "@angular/router";
import {RoutePath} from "../enums/route-path.enum";
import {AccountService} from "../../account/services/account.service";

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(
    private accountService: AccountService,
    private router: Router
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authReq = request.clone({
      headers: request.headers.set('Accept', 'application/json')
    });

    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          localStorage.removeItem('isLogged');
          this.router.navigate([RoutePath.Login]);
        }

        return throwError(() => error);
      })
    );
  }
}
