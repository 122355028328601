import {PageEntity} from "./page.entity";
import {Order, OrderEntity} from "./order.entity";

export interface OrdersPage {
  page: number;
  per_page: number;
  count: number;
  pages: number;
  elements: Order[];
}

export class OrdersPageEntity extends PageEntity<OrderEntity> {
  constructor(
    public override page: number,
    public override perPage: number,
    public override count: number,
    public override pages: number,
    public override elements: OrderEntity[],
  ) {
    super(page, perPage, count, pages, elements);
  }

  public static override fromData(data: OrdersPage): OrdersPageEntity {
    return new OrdersPageEntity(
      data.page,
      data.per_page,
      data.count,
      data.pages,
      data.elements.map((el) => OrderEntity.fromData(el)),
    );
  }
}
