<app-base-control
  [childControlRef]="childTemplate"
  [validationErrors]="validationErrors"
>
  <ng-template #childTemplate>
    <label
      [class.error]="formControl.touched && formControl.valid !== true"
      [ngClass]="right ? 'right' : 'left'">
      <input
        #checkboxElement
        type="checkbox"
        [placeholder]="placeholder"
        [value]="value"
        [disabled]="disabled"
        (change)="onChange($event)"
      />
      <p class="s">
        {{ label }}
      </p>
    </label>
  </ng-template>
</app-base-control>
