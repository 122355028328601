<p class="m semi header-item">{{ 'PANEL.FOOTER.CONTACT.HEADER' | translate }}</p>
<div class="info-item">
  <p class="s mb-tiny">{{ 'PANEL.FOOTER.CONTACT.INFO_1' | translate }}</p>
  <p class="s">{{ 'PANEL.FOOTER.CONTACT.INFO_2' | translate }}</p>
</div>

<div class="options-grid">
  <div class="flex-row center email-item">
    <app-icon
      [icon]="Icon.Envelop"
      [clickable]="false"
    ></app-icon>
    <p class="s ml-small">
      {{ 'PANEL.FOOTER.CONTACT.EMAIL_ADDRESS' | translate }}
    </p>
  </div>

  <div class="flex-row center phone-item">
    <app-icon
      [icon]="Icon.Phone"
      [clickable]="false"
    ></app-icon>
    <p class="s ml-small">
      {{ 'PANEL.FOOTER.CONTACT.PHONE_NUMBER' | translate }}
    </p>
  </div>

  <div class="flex-row center faq-item">
    <app-icon
      [icon]="Icon.Collection"
      [clickable]="false"
    ></app-icon>
    <a href="{{ apiUrlsProvider.faqUrl() }}">
      <p class="s ml-small">
        {{ 'PANEL.FOOTER.CONTACT.FAQ' | translate }}
      </p>
    </a>
  </div>
</div>
