import {Component, Input} from '@angular/core';
import {InvoiceAddressEntity} from "../../entities/invoice-address.entity";

@Component({
  selector: 'app-invoice-address',
  templateUrl: './invoice-address.component.html'
})
export class InvoiceAddressComponent {

  @Input()
  invoiceAddress!: InvoiceAddressEntity;
}
