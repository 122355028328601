import {environment} from '../../../../environments/environment';
import {TranslateService} from "@ngx-translate/core";
import {Inject, Injectable} from "@angular/core";
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';
import {BehaviorSubject, Subject} from "rxjs";

@Injectable()
export class ApiUrlsProvider {

  public accountUrlPrepared$: Subject<string> = new Subject<string>();
  public backUrl$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(@Inject('Window') private window: Window,
              private translateService: TranslateService) {
  }

  public clientAppBaseUrl(): string {
    return `${environment.clientAppBaseUrl}`;
  }

  public apiUrl(path = ''): string {
    return `${environment.apiBaseUrl}/${path}`;
  }

  public apiLanguageUrl(path: string): string {
    return `${environment.apiBaseUrl}/${this.translateService.currentLang}/api/${path}`;
  }

  public homeUrl(): string {
    return `${environment.apiBaseUrl}/${this.translateService.currentLang}`;
  }

  public searchDesignUrl(searchValue: string): string {
    const url = this.translateService.instant(_('GENERAL.URL.DESIGN_SEARCH'));
    return `${this.homeUrl()}/${url}?q=${searchValue}&source=user`;
  }

  public digitalFabricPrintingUrl(): string {
    const url = this.translateService.instant(_('GENERAL.URL.DIGITAL_FABRIC_PRINTING'));
    return `${this.homeUrl()}/${url}`;
  }

  public forBusinessUrl(): string {
    const url = this.translateService.instant(_('GENERAL.URL.FOR_BUSINESS'));
    return `${this.homeUrl()}/${url}`;
  }

  public fabricCollectionsUrl(): string {
    const url = this.translateService.instant(_('GENERAL.URL.FABRIC_COLLECTIONS'));
    return `${this.homeUrl()}/${url}`;
  }

  public aboutUrl(): string {
    const url = this.translateService.instant(_('GENERAL.URL.ABOUT'));
    return `${this.homeUrl()}/${url}`;
  }

  public cartUrl(): string {
    const url = this.translateService.instant(_('GENERAL.URL.CART'));
    return `${this.homeUrl()}/${url}`;
  }

  public faqUrl(): string {
    const url = this.translateService.instant(_('GENERAL.URL.FAQ'));
    return `${this.apiUrl(url)}`;
  }

  public uploadedImageUrl(imageId: number): string {
    return `${this.homeUrl()}/uploaded/${imageId}`;
  }

  public prepareLanguageAccountUrl(newLanguage: string): void {
    this.translateService.getTranslation(newLanguage).pipe().subscribe(() => {
      const account = this.translateService.getParsedResult(this.translateService.translations[newLanguage], _('GENERAL.URL.ACCOUNT'));
      const currentUrlSplit = this.window.location.href.split('#');
      const postHash = currentUrlSplit[1];

      this.accountUrlPrepared$.next(`${environment.apiBaseUrl}/${newLanguage}/`.concat(account, '#', postHash));
    });
  }
}
