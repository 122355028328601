<app-no-content
  *ngIf="!pageEntity || pageEntity.isEmpty"
  [message]="noContentMessage"
></app-no-content>

<ng-container *ngIf="pageEntity && pageEntity.elements.length !== 0">
  <table>
    <tr>
      <ng-container *ngFor="let column of columns">
        <th
          [style.width.%]="column.width"
          [style.min-width.%]="column.width"
          [class.hidden]="column.hiddenOnMobile"
        >
          <div class="flex-row center"
               [class.actions]="column.columnType === ColumnType.Event">
            {{ column.header | translate }}
            <app-sort-button
              *ngIf="column.sortable && sortable"
              [columnDefinition]="column"
            ></app-sort-button>
          </div>
        </th>
      </ng-container>
    </tr>

    <ng-container *ngIf="!loading">
      <ng-container *ngIf="pageEntity">
        <ng-container *ngFor="let element of pageEntity.elements! let i = index">
          <tr>
            <ng-container *ngFor="let column of columns">
              <ng-container [ngSwitch]="column.columnType">
                <td [class.hidden]="column.hiddenOnMobile">

                  <ng-container *ngSwitchCase="ColumnType.Custom">
                    <ng-container
                      [ngTemplateOutlet]="customTemplateRef"
                      [ngTemplateOutletContext]="{
                      $implicit: element
                    }"
                    ></ng-container>
                  </ng-container>

                  <ng-container *ngSwitchCase="ColumnType.Boolean">
                    <app-icon
                      [icon]="element[column.fieldName] === true ? Icon.BookmarkFilled : Icon.BookmarkEmpty"
                      [clickable]="false">
                    </app-icon>
                  </ng-container>

                  <ng-container *ngSwitchCase="ColumnType.Image">
                    <img
                      src="{{ element[column.fieldName] }}"
                      (click)="magnifyImage.next(element[column.fieldName])"
                    />
                  </ng-container>

                  <ng-container *ngSwitchCase="ColumnType.Text">
                    <p class="visible-on-desktop">
                      {{ element[column.fieldName]}}
                    </p>
                    <p class="visible-on-mobile">
                      {{ element[column.fieldName] | trim: 70}}
                    </p>
                  </ng-container>

                  <ng-container *ngSwitchCase="ColumnType.Date">
                    <div class="visible-on-desktop">
                      {{ element[column.fieldName] | date : DateTimeFormat.DateTime }}
                    </div>
                    <div class="visible-on-mobile">
                      {{ element[column.fieldName] | date : DateTimeFormat.Date }}
                    </div>
                  </ng-container>

                  <ng-container *ngSwitchCase="ColumnType.Currency">
                    {{ element[column.fieldName] | uppercase }}
                  </ng-container>

                  <ng-container *ngSwitchCase="ColumnType.Enum">
                    {{ element[column.fieldName] | enumTranslate : column.fieldName | translate }}
                  </ng-container>

                  <ng-container *ngSwitchCase="ColumnType.Event">
                    <app-actions-cell
                      [rowEvents]="rowEvents"
                      [element]="element"
                      [rowNumber]="i"
                      [mobileActionType]="column.mobileActionType"
                      [isExpandable]="expandable">
                    </app-actions-cell>
                  </ng-container>

                </td>
              </ng-container>
            </ng-container>
          </tr>
          <tr app-expandable-row
              [width]="columns.length"
              [hidden]="expandedRow !== i"
              [element]="element"
              [rowTemplate]="customRowTemplateRef">
          </tr>
        </ng-container>
      </ng-container>
    </ng-container>
  </table>

  <app-loading-indicator
    *ngIf="loading"
    [loadingIndicatorType]="LoadingIndicatorType.Table">
  </app-loading-indicator>

  <app-paginator
    [paginator]="paginator"
    [pageEntity]="pageEntity"
    [editablePageSize]="editablePageSize"
    (paginationChanged)="changePagination$.next($event)"
  ></app-paginator>
</ng-container>
