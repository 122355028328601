import {Component, Input} from '@angular/core';
import {Icon} from "../../enums/icon.enum";
import {OrderDetailsEntity} from "../../entities/order-details.entity";

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent {

  @Input()
  orderDetails!: OrderDetailsEntity;

  protected readonly Icon = Icon;
}
