<app-chip
  *ngIf="orderItem.status === OrderStatus.Placed"
  [text]="orderItem.status | enumTranslate : 'status' | translate"
  [color]="'#50A3DB'"
></app-chip>

<app-chip
  *ngIf="orderItem.status === OrderStatus.Paid"
  [text]="orderItem.status | enumTranslate : 'status' | translate"
  [color]="'#76CC4B'"
></app-chip>

<app-chip
  *ngIf="orderItem.status === OrderStatus.InProgress"
  [text]="orderItem.status | enumTranslate : 'status' | translate"
  [color]="'#F38836'"
></app-chip>

<app-chip
  *ngIf="orderItem.status === OrderStatus.Sent"
  [text]="orderItem.status | enumTranslate : 'status' | translate"
  [color]="'#B156BC'"
></app-chip>

<app-chip
  *ngIf="orderItem.status === OrderStatus.Cancelled"
  [text]="orderItem.status | enumTranslate : 'status' | translate"
  [color]="'#E94949'"
></app-chip>
