import {AccountManager, AccountManagerEntity} from "./account-manager.entity";

export interface Account {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  created_at: Date;
  account_manager: AccountManager
}

export class AccountEntity {
  constructor(
    public id: number,
    public firstName: string,
    public lastName: string,
    public fullName: string,
    public email: string,
    public createdAt: Date,
    public accountManager: AccountManagerEntity
  ) {
  }

  public static fromData(data: Account): AccountEntity {
    return new AccountEntity(
      data.id,
      data.first_name,
      data.last_name,
      data.first_name.concat(' ', data.last_name),
      data.email,
      data.created_at,
      AccountManagerEntity.fromData(data.account_manager)
    );
  }

  public toData(): Account {
    return {
      id: this.id,
      first_name: this.firstName,
      last_name: this.lastName,
      created_at: this.createdAt,
      email: this.email,
      account_manager: this.accountManager.toData()
    };
  }
}
