<div class="sidebar flex-column space-between">
  <div>
    <div class="upload mb-big temporary-hidden">
      <app-extended-button
        class="border dashed wide tall square"
        [icon]="Icon.Upload"
        [text]="'PANEL.LAYOUT.SIDEBAR.UPLOAD_DESIGN_BUTTON' | translate"
      ></app-extended-button>
    </div>

    <app-menu [menuItemType]="MenuItemType.LeftMenu"></app-menu>
  </div>

  <div class="flex-column temporary-hidden">
    <ng-container *ngIf="(accountData$ | async)?.accountManager as accountManager">
      <p class="s mb-tiny">
        {{ 'PANEL.LAYOUT.SIDEBAR.INDIVIDUAL_CUSTOMER_CARE' | translate }}
      </p>

      <p class="m bold mb-medium">{{ accountManager.fullName }}</p>

      <div class="flex-row center mb-medium">
        <app-icon
          class="mr-small"
          [icon]="Icon.Envelop"
          [clickable]="false"
        ></app-icon>
        <p class="s semi">
          {{ accountManager.email }}
        </p>
      </div>

      <div class="flex-row center">
        <app-icon
          class="mr-small"
          [icon]="Icon.Phone"
          [clickable]="false"
        ></app-icon>
        <p class="s semi">
          {{ 'PANEL.LAYOUT.SIDEBAR.CUSTOMER_CARE_PHONE' | translate }}
        </p>
      </div>
    </ng-container>
  </div>
</div>
