<div class="header-container flex-row space-around center">
  <div
    id="links"
    class="links flex-row ml-big"
  >
    <a href="{{ apiUrlsProvider.digitalFabricPrintingUrl() }}">
      <p class="s semi hoverable">
        {{ 'PANEL.HEADER.LINKS.BUTTON_FABRIC_PRINTING' | translate }}
      </p>
    </a>
    <a
      class="ml-big"
      href="{{ apiUrlsProvider.forBusinessUrl() }}"
    >
      <p class="s semi hoverable">
        {{ 'PANEL.HEADER.LINKS.BUTTON_FOR_BUSINESS' | translate }}
      </p>
    </a>
    <a
      class="ml-big"
      href="{{ apiUrlsProvider.fabricCollectionsUrl() }}"
    >
      <p class="s semi hoverable">
        {{ 'PANEL.HEADER.LINKS.BUTTON_PATTERNS' | translate }}
      </p>
    </a>
    <a
      class="ml-big"
      href="{{ apiUrlsProvider.aboutUrl() }}"
    >
      <p class="s semi hoverable">
        {{ 'PANEL.HEADER.LINKS.BUTTON_ABOUT' | translate }}
      </p>
    </a>
  </div>

  <app-logo></app-logo>

  <div
    id="options"
    class="options flex-row center mr-big"
  >
    <form
      [formGroup]="form"
      class="flex-row center"
      novalidate
    >
      <app-search-input
        formControlName="searchControl"
        class="mr-big"
        [placeholder]="'PANEL.HEADER.PLACEHOLDER_SEARCH_FILE' | translate"
        (keyup.enter)="searchValueChanged$.next($event)"
      ></app-search-input>
    </form>

    <app-language-switch></app-language-switch>

    <div
      class="logout flex-column center ml-big"
      (click)="logout$.next()"
    >
      <app-icon [icon]="Icon.User"></app-icon>
      <p class="s semi">
        {{ 'PANEL.HEADER.BUTTON_LOGOUT' | translate }}
      </p>
    </div>
  </div>
</div>
