export interface ShippingAddress {
  first_name: string,
  last_name: string,
  street: string,
  house_no: string,
  postcode: string,
  city: string,
  country: string,
  phone: string,
  email: string
}

export class ShippingAddressEntity {
  constructor(
    public firstName: string,
    public lastName: string,
    public fullName: string,
    public street: string,
    public houseNo: string,
    public postCode: string,
    public city: string,
    public country: string,
    public phone: string,
    public email: string
  ) {
  }

  public static fromData(data: ShippingAddress): ShippingAddressEntity {
    return new ShippingAddressEntity(
      data.first_name,
      data.last_name,
      data.first_name.concat(' ', data.last_name),
      data.street,
      data.house_no,
      data.postcode,
      data.city,
      data.country,
      data.phone,
      data.email
    );
  }
}
