import {Component, OnInit} from '@angular/core';
import {Icon} from "../../../../shared/enums/icon.enum";
import {ApiUrlsProvider} from "../../../../shared/utils/api-url-provider";
import {CartService} from "../../../services/cart.service";
import {BaseComponent} from "../../../../shared/components/base/base.component";
import {Subject, takeUntil} from "rxjs";
import {CartSummaryEntity} from "../../../../shared/entities/cart-summary.entity";
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';
import {OrdersService} from "../../../../orders/services/orders.service";

@Component({
  selector: 'app-cart-link',
  templateUrl: './cart-link.component.html',
  styleUrls: ['./cart-link.component.scss']
})
export class CartLinkComponent extends BaseComponent implements OnInit {

  public cartSummary$: Subject<CartSummaryEntity> = new Subject<CartSummaryEntity>();
  public redirectToCart$: Subject<void> = new Subject<void>();

  constructor(
    private apiUrlsProvider: ApiUrlsProvider,
    private cartService: CartService,
    private ordersService: OrdersService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initReactiveBinding();
    this.cartService.loadCartSummary();

    this.redirectToCart$.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(() => {
        window.open(this.apiUrlsProvider.cartUrl(), "_blank");
      });
  }

  private initReactiveBinding(): void {
    this.bind(this.cartService.cartSummary$, this.cartSummary$);
    this.bind(this.ordersService.cartSummary$, this.cartSummary$);
  }

  protected readonly Icon = Icon;

  cartItemsMap:
    { [k: string]: string } = {
    '=0.0': _('PANEL.LAYOUT.CONTENT.CART_LINK.CART_CONTENT_EMPTY'),
    '=1.0': _('PANEL.LAYOUT.CONTENT.CART_LINK.CART_CONTENT_ONE'),
    '=2.0': _('PANEL.LAYOUT.CONTENT.CART_LINK.CART_CONTENT_MANY'),
    '=3.0': _('PANEL.LAYOUT.CONTENT.CART_LINK.CART_CONTENT_MANY'),
    '=4.0': _('PANEL.LAYOUT.CONTENT.CART_LINK.CART_CONTENT_MANY'),
    'other': _('PANEL.LAYOUT.CONTENT.CART_LINK.CART_CONTENT_OTHER'),
  };
}
