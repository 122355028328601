import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {SortEvent} from "../sorting/sort-event";
import {FieldName} from "../../../enums/field-name.enum";

@Injectable({
  providedIn: 'root'
})
export class ExtendedTableService {

  public sortingChanged$: BehaviorSubject<SortEvent> = new BehaviorSubject<SortEvent>({
    fieldName: FieldName.Empty,
    sortDirection: true
  });

  public rowExpanded$: Subject<number | null> = new Subject<number | null>();

  constructor() { }
}
