import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {DialogRef} from "@ngneat/dialog";
import {ColumnDefinition} from "../../column-definition";
import {SortEvent} from "../sort-event";
import {Subject, takeUntil} from "rxjs";
import {BaseComponent} from "../../../base/base.component";

@Component({
  selector: 'app-sort-dialog',
  templateUrl: './sort-dialog.component.html',
  styleUrls: ['./sort-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SortDialogComponent extends BaseComponent implements OnInit {

  public applySorting$: Subject<void> = new Subject<void>();
  public sortingOptionChanged$: Subject<SortEvent> = new Subject<SortEvent>();

  public sortableColumns!: ColumnDefinition[];
  private sortEvent!: SortEvent;

  constructor(
    public dialogRef: DialogRef
  ) {
    super();
  }

  ngOnInit(): void {
    const data = this.dialogRef.data;
    this.sortableColumns = data.sortableColumns;

    this.applySorting$.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.dialogRef.close(this.sortEvent);
      });

    this.sortingOptionChanged$.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe((sortEvent) => {
        this.sortEvent = sortEvent;
      });
  }
}
