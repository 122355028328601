<ng-container *ngFor="let sortableColumn of sortableColumns">
  <app-sort-option
    [fieldName]="sortableColumn.fieldName"
    [columnType]="sortableColumn.columnType"
    [sortDirection]="false"
    (sortingChanged)="sortingOptionChanged$.next($event)"
  ></app-sort-option>
  <app-sort-option
    [fieldName]="sortableColumn.fieldName"
    [columnType]="sortableColumn.columnType"
    [sortDirection]="true"
    (sortingChanged)="sortingOptionChanged$.next($event)"
  ></app-sort-option>
</ng-container>

<div class="mt-medium">
  <app-extended-button
    class="filled wide"
    [text]="'GENERAL.SORT.DIALOG.SORT' | translate"
    (clicked)="applySorting$.next()"
  ></app-extended-button>
</div>
