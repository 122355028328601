import {Component, OnInit} from '@angular/core';
import {AccountService} from "../../../../../account/services/account.service";
import {ReplaySubject} from "rxjs";
import {AccountEntity} from "../../../../../shared/entities/account.entity";
import {BaseComponent} from "../../../../../shared/components/base/base.component";
import {Icon} from "../../../../../shared/enums/icon.enum";
import {MenuItemType} from "../../../../../shared/enums/menu-item-type.enum";
import {SubmenuType} from "../../../../../shared/enums/submenu-type.enum";

@Component({
  selector: 'app-left-container',
  templateUrl: './left-container.component.html',
  styleUrls: ['./left-container.component.scss']
})
export class LeftContainerComponent extends BaseComponent implements OnInit {

  public accountData$: ReplaySubject<AccountEntity> = new ReplaySubject<AccountEntity>();

  constructor(private accountService: AccountService) {
    super();
  }

  ngOnInit(): void {
    this.initReactiveBinding();
  }

  private initReactiveBinding(): void {
    this.bind(this.accountService.accountData$, this.accountData$);
  }

  protected readonly Icon = Icon;
  protected readonly MenuItemType = MenuItemType;
  protected readonly SubmenuType = SubmenuType;
}
