<ng-container *ngIf="!isPNG">
  <svg-icon
    *ngIf="icon"
    [svgStyle]="{ fill: color }"
    [src]="apiUrlsProvider.clientAppBaseUrl() + '/assets/images/icons/' + icon + '.svg'"
  ></svg-icon>
</ng-container>

<ng-container *ngIf="isPNG">
  <img src="{{apiUrlsProvider.clientAppBaseUrl() + '/assets/images/icons/' + icon + '.png'}}">
</ng-container>
