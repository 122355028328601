import {Component, Input, TemplateRef} from '@angular/core';
import {NgControl} from '@angular/forms';
import {ValidationError} from './validation-error';

@Component({
  selector: 'app-base-control',
  templateUrl: './base-control.component.html'
})
export class BaseControlComponent {
  @Input()
  childControlRef!: TemplateRef<any>;

  @Input()
  initialValue: any;

  @Input()
  disabled!: boolean;

  @Input()
  placeholder!: string;

  @Input()
  label!: string;

  @Input()
  validationErrors!: ValidationError[];

  value: any;

  protected _onChange = (_: any) => {
  };
  protected _onTouched = () => {
  };

  formControl!: NgControl;

  constructor(public ngControl: NgControl) {
    if (this.ngControl) {
      this.formControl = this.ngControl;
    }
  }

  onChange(event: any) {
    this.formControl.control?.markAsTouched();
    this.formControl.control?.setValue(event.target.value);
    this._onChange(event.target.value);
  }
}
