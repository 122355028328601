import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {FormService} from "../services/form.service";
import {BaseComponent} from "../../shared/components/base/base.component";

@Component({
  selector: 'app-login-container',
  templateUrl: './login-container.component.html',
  styleUrls: ['./login-container.component.scss']
})
export class LoginContainerComponent extends BaseComponent implements OnInit {

  public isPasswordForgotten$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private formService: FormService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initReactiveBindings();
  }

  private initReactiveBindings(): void {
    this.bind(this.formService.passwordForgotten$, this.isPasswordForgotten$);
  }
}
