<div
  [ngClass]="vertical ? 'flex-column' : 'flex-row'"
  class="content-justification"
>
  <ng-container *ngFor="let menuItem of menuItems">
    <div [class.border]="vertical"
         [class.distance]="MenuItemType.LeftMenu">
      <app-menu-item
        [menuItem]="menuItem"
        [vertical]="vertical"
        (toggleSubmenusVisibility)="
          submenuType === SubmenuType.Dropdown ? toggleSubmenusDropdown$.next(menuItem) : showSubmenusDialog$.next(menuItem)
        "
      ></app-menu-item>
      <ng-container *ngIf="submenuType === SubmenuType.Dropdown">
        <ng-container *ngFor="let submenuItem of menuItem?.submenus">
          <app-submenu-item
            [parentMenuItem]="menuItem"
            [submenuItem]="submenuItem"
          ></app-submenu-item>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</div>
