import {NgModule} from '@angular/core';
import {OnboardingRoutingModule} from './onboarding-routing.module';
import {LoginComponent} from './login-container/login/login.component';
import {OnboardingComponent} from './onboarding.component';
import {RegistrationComponent} from './login-container/registration/registration.component';
import {SharedModule} from '../shared/shared.module';
import {AuthHttpService} from '../shared/services/http/auth-http.service';
import {ResetPasswordComponent} from './login-container/reset-password/reset-password.component';
import {SetPasswordComponent} from './reset-container/set-password/set-password.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { PaymentOptionsComponent } from './footer/payment-options/payment-options.component';
import { DeliveryOptionsComponent } from './footer/delivery-options/delivery-options.component';
import { ContactComponent } from './footer/contact/contact.component';
import { LoginContainerComponent } from './login-container/login-container.component';
import { ResetContainerComponent } from './reset-container/reset-container.component';
import {AuthGuard} from "../shared/guards/auth.guard";

@NgModule({
  declarations: [
    LoginComponent,
    OnboardingComponent,
    RegistrationComponent,
    ResetPasswordComponent,
    SetPasswordComponent,
    FooterComponent,
    HeaderComponent,
    PaymentOptionsComponent,
    DeliveryOptionsComponent,
    ContactComponent,
    LoginContainerComponent,
    ResetContainerComponent,
  ],
  imports: [OnboardingRoutingModule, SharedModule],
  providers: [AuthHttpService, AuthGuard]
})
export class OnboardingModule {}
