<div class="container">
  <h2 class="mb-big">{{ 'FTL.FORMS.SET_PASSWORD.HEADER' | translate }}</h2>

  <ng-container *ngIf="!resetPasswordConfirmed">
    <p class="m semi mb-medium">
      {{ 'FTL.FORMS.SET_PASSWORD.NEW_PASSWORD' | translate }}
    </p>
    <p class="s mb-big">
      {{ 'FTL.FORMS.SET_PASSWORD.RESET_PASSWORD_REQUEST' | translate }}
    </p>

    <app-extended-button
      class="filled wide"
      [text]="'FTL.FORMS.SET_PASSWORD.BUTTON_RESET_PASSWORD' | translate"
      (clicked)="confirmPasswordReset$.next()"
    ></app-extended-button>
  </ng-container>

  <ng-container *ngIf="resetPasswordConfirmed && (passwordResetSuccess$ | async) !== true">
    <p class="m semi mb-medium">
      {{ 'FTL.FORMS.SET_PASSWORD.NEW_PASSWORD' | translate }}
    </p>
    <p class="s mb-big">
      {{ 'FTL.FORMS.SET_PASSWORD.NEW_PASSWORD_HINT' | translate }}
    </p>

    <form
      [formGroup]="form"
      (ngSubmit)="submit$.next([$event, form])"
    >
      <app-extended-input
        formControlName="passwordControl"
        [icon]="Icon.Key"
        [label]="'FTL.FORMS.SET_PASSWORD.PASSWORD.LABEL' | translate"
        [placeholder]="'FTL.FORMS.SET_PASSWORD.PASSWORD.PLACEHOLDER' | translate"
        [visiblePassword]="true"
        [validationErrors]="[
          {
            key: 'required',
            value: 'FTL.FORMS.VALIDATION.PASSWORD.REQUIRED' | translate
          },
          {
            key: 'minlength',
            value: 'FTL.FORMS.VALIDATION.PASSWORD.MINLENGTH' | translate
          }
        ]"
        type="password"
      ></app-extended-input>

      <div class="mt-big">
        <app-extended-button
          class="filled wide"
          [text]="'FTL.FORMS.SET_PASSWORD.BUTTON_RESET_PASSWORD' | translate"
          [disabled]="form.invalid || (authServiceLoading$ | async)!"
        ></app-extended-button>
      </div>
    </form>

    <p
      class="text-error"
      *ngIf="errorMessage"
    >
      {{ errorMessage }}
    </p>
  </ng-container>

  <ng-container *ngIf="(passwordResetSuccess$ | async) === true">
    <div class="email-container">
      <i
        class="fa-regular fa-circle-check"
        style="color: #01b123"
      ></i>
      <p class="s">{{ 'FTL.FORMS.SET_PASSWORD.PASSWORD_RESET' | translate }}</p>
    </div>

    <app-extended-button
      class="filled wide"
      [text]="'FTL.FORMS.SET_PASSWORD.BUTTON_LOGIN_NOW' | translate"
    ></app-extended-button>
  </ng-container>
</div>
