import {Component} from '@angular/core';
import {ApiUrlsProvider} from "../../utils/api-url-provider";

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent {

  constructor(public apiUrlsProvider: ApiUrlsProvider) {
  }
}
