import {PageEntity} from "./page.entity";
import {OrderItem, OrderItemEntity} from "./order-item.entity";

export interface OrderItemsPage {
  page: number;
  per_page: number;
  count: number;
  pages: number;
  elements: OrderItem[];
}

export class OrderItemsPageEntity extends PageEntity<OrderItemEntity> {
  constructor(
    public override page: number,
    public override perPage: number,
    public override count: number,
    public override pages: number,
    public override elements: OrderItemEntity[],
  ) {
    super(page, perPage, count, pages, elements);
  }

  public static override fromData(data: OrderItemsPage): OrderItemsPageEntity {
    return new OrderItemsPageEntity(
      data.page,
      data.per_page,
      data.count,
      data.pages,
      data.elements.map((el) => OrderItemEntity.fromData(el)),
    );
  }
}
