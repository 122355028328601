import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BottomMenuService {
  public bottomMenuVisibility$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor() {
  }

  public show(): void {
    this.bottomMenuVisibility$.next(true);
  }

  public hide(): void {
    this.bottomMenuVisibility$.next(false);
  }
}
